
.create-person-container {
  .ant-form-item .ant-form-item-label > label {
    white-space: normal;
  }
  .bottom-actions {
    display: flex;
    justify-content: space-between;
    padding-right: 128px;
    // flex-wrap: wrap-reverse;
  }
}
