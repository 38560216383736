/* ---------------------------

	Overview

--------------------------- */

.overview-pagination {
  float: left;

  .pagination {
    float: left;
    .fontSize(13px);
    .lineHeight(16px);
    font-weight: 300;
    position: relative;
    color: @grey;

    &.prev {
      padding-left: 20px;
      padding-right: 10px;
      background: url('../../img/icon-pagination-back.svg') no-repeat left;
    }

    &.next {
      padding-right: 20px;
      padding-left: 10px;
      background: url('../../img/icon-pagination-next.svg') no-repeat right;
    }

    &.day {
      color: #000;
      font-weight: 700;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

}

h1 {
  .fontSize(18px);
  color: @heading-color;
}

.overview-view-type {
  float: right;
  margin: -1px 20px 0 0;

  ul {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      float: left;
      margin: 0;
      padding: 0 5px;

      a {
        float: left;
      }
    }
  }
}

.overview-section{
  width: 75%;
  min-height: 150px;

  h2{
    margin-top: 20px;
  }
  .ant-select{
    margin-top: 5px;
    width: 50%;
  }
}


/* ---------------------------

	Overview Columns

--------------------------- */

.overview-columns {
  float: left;
  width: calc(~"100% + 30px");
  margin-left: -15px;

  .overview-column {
    float: left;
    width: 33.3333%;
    padding: 0 15px;
    margin-bottom: 40px;

    h2 {
      float: left;
      .fontSize(@font-size);
      .lineHeight(20px);
      color: @grey;
      font-weight: 400;
    }
    h3{
      .fontSize(@font-size);
    }

    .activity-cards {
      float: left;
      width: 100%;
      margin-top: 15px;
      .scrollBar(70vh);
    }

    &.current-day {
      h2 {
        font-weight: 700;
        color: #000;
      }
    }
  }



  @media (max-width: 1000px) {
    .overview-column {
      width: 100%;

      .activity-cards {
        max-height: none;
      }
    }
  }
}


/* ---------------------------

	Overview Table

--------------------------- */

.overview-tables {
  float: left;
  width: 100%;

  .overview-table {
    float: left;
    width: 100%;
    margin-bottom: 30px;

    h2 {
      float: left;
      .fontSize(17px);
      .lineHeight(20px);
      color: @grey;
      font-weight: 400;
    }

    &.current-day {
      h2 {
        font-weight: 700;
        color: #000;
      }
    }

    table {
      margin-top: 15px;
    }
  }
}


.name-container.single .name-tag-columns, .name-container.multiple .name-tag-columns{
  width: 100%;

  .name-tag-label{
    float: left;
    margin-right: @x-small;
    .site-badge-count{
      margin-top: 3px;
    }
  }

}
.comma-list{
  .comma:not(:empty) ~ .comma:not(:empty):before {
    // MARK: This breaks SEO and copy/paste!
    content: ", ";
  }
}


.white{
  background-color: white;
}

//HCP & HCO DETAIL VIEW


.detail-view-row{

}

.detail-view-label{
  font-size: 12px;
  margin-left: 10px;

  &.right{
    float: right;
  }

}
.detail-view-label:after{
  content: ":";
}
.detail-view-value{
  font-size: 12px;
  font-weight: bold;
  margin-right: @micro;
  word-break: break-word;
}

.detail-view-section{
  margin-bottom: 40px;
  margin-left: 25px;
  h2{
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
  }
  .detail-view-sub-section{
    margin-left: 20px;
    margin-top: 20px;
  }
}
.comma-list{
  .comma:not(:empty) ~ .comma:not(:empty):before {
    content: ", ";
  }
}



.horizontal-scrolling {

  .scrollmenu {
    overflow: auto;
    display: flex;
  }




  /*.scrollmenu-items-transparent{
!*    flex-shrink: 0;
    width: 375px;
    min-height: 175px;
    margin-bottom: 20px;
    background-color: red;*!
    .ant-carousel{
      width: 350px;
    }
    .ant-carousel .slick-slide {
      width: 360px;
      margin: auto;
      background-color: red;
    }

    &.double{
      width: 732px;

      .contact-card-container.large{
        margin-right: 16px;
      }

      .ant-carousel{
        width: 732px;
      }
      .ant-carousel .slick-slide {
        width: 732px;
      }
      .slick-dots-bottom{
        padding-right: 25px;
      }

    }

  }*/

  /*.scrollmenu-items {
    flex-shrink: 0;
    width: 335px;
    min-height: 175px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
    margin-right: 34px;
    margin-bottom: 20px;
    padding: 10px;

    .note-description {
      margin-left: 10px;
    }

    .card-info {
      margin-top: 10px;
    }

    .icon {
      display: inline-block;
      text-align: center;
      position: relative;
      align-self: start;
      margin-bottom: 15px;
      height: 60px;
      width: 60px;
      line-height: 60px;
      float: left;

      img {
        width: 37px;
      }


      &.icon-rounded {
        border-radius: 50%;
        background: #F5F5F5;
      }


      .icon-badge {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 30px;
        height: 30px;
        background: @blue;
        line-height: 30px;
        text-align: center;
        color: #fff;
        font-weight: 700;
        border-radius: 50%;
      }
    }


    h3 {
      float: left;
      width: 100%;
      .fontSize(14px);
      .lineHeight(18px);
      color: #000;
      font-weight: 700;
      margin-bottom: 0;
    }

    h4 {
      .fontSize(12px);
      .lineHeight(18px);
      color: #000;
      font-weight: 600;
      margin-bottom: 0;
    }

    h5 {
      .fontSize(12px);
      .lineHeight(18px);
      color: #000;
      margin-bottom: 0;
      width: 100%;
      padding-left: 0px;
    }

  }*/

  @media screen and (max-width: @tablet-width) {
    .scrollmenu-items {
      width: 300px;
    }
  }


  .horizontal-menu-link-container{
    text-decoration: underline;
    color:#777;
  }



  .horizontal-menu-link-container {
    margin-top: 30px;
  }

}



.section-actions{
  position: relative;
  z-index: 10000;
}



.ant-carousel .slick-dots{
  position: unset;
  margin-top: 20px;
  height: 15px;
  border-radius: 50px;
}

.ant-carousel .slick-dots button{
  height: 15px;
  border-radius: 50px;

}

.ant-carousel .slick-dots li.slick-active button{
  background-color: @primary-color;
  height: 15px;
  width: 15px;
}

.ant-carousel .slick-dots li.slick-active{
  width: 15px;
}

.ant-carousel .slick-dots li button{
  background-color: #85B0D6;

  height: 15px;
  width: 15px;
  border-radius: 50px;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
