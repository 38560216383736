@import './components/BulkPersonsToProject/_bulkPersonsToProject';


.project-container {
  .render-card .contact-card-container {
    padding-left: 0;
    padding-top: 0;
  }
  .activity-notes-container{
    clear: both;
    padding-top: 20px;
  }

  .contact-card-container {
    padding-left: 0;
  }

  .ant-input-disabled{
    background-color: white;
    color:@text-color
  }

}
.project-product-brand {
  .contact-section-top {
    margin-left: 15px;
  }

  .contact-card-container {
    margin-top: 6px;
  }

}


.project-create-container {
  .project-create {
    margin-bottom: 10px;
  }
}

.project_product, .project_activity_affiliation, .project-location, .project-notes-container, .project_attendee {
  margin-top: 40px;
}
