
.lov-modal-criteria-search-builder {
  .lov-modal-search-criteria {
    margin-bottom: 25px;
  }

  .global-badge sup {
    background-color: @secondary-color;
  }
  .globe {
    color: @secondary-color;
  }
  .local-badge sup {
    background-color: @primary-color;
  }

}
