
.sandbox-view-container {
  .ant-card-body {
    .card-content {
      display: flex;
      justify-content: space-between;

      .main-content {
        display: flex;
        flex-direction: column;

      }
      .ant-progress-status-exception {
        .ant-progress-steps-item-active { background-color: @error-color !important; }
      }
      .ant-progress-status-normal {
        .ant-progress-steps-item-active { background-color: @success-color !important; }
      }
    }
  }

}
