
// TODO: Rename to content-section-container
.detail-view-section {
  .no-left-margin {
    margin-left: 0;
  }
  // TODO: For legacy reasons. Figure out a way to remove!
  .detail-view-sub-section{
    margin-left: 0px;
  }
  .detail-view-label{
    margin-left: 0px;
  }
}
