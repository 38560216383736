
.data-validation-history-log-container {

  .timeline-log{
    margin-left: 10px;

    .timeline-text{
      padding-left: 2px;
    }
  }

}
