@import "_activityNotes";
@import "_detailsPageSection";
@import "./Heading/_activityHeadingDetails";
@import "./ActivityResponsibleUsers/_activityResponsibleUsers";
@import "./Top/_activityDetailsTopComponents";
@import "./DateTimeSpan/TimeSpanForm/_timeSpan";
@import "./ActivityPersons/_activityDetailsPersons";

.responsible-user-comp {

  .user-details {
    span.user-label {
      color: @primary-color;
      cursor: pointer;
    }
  }
}

.activity-date {

  i.date-edit {
    margin-left: 10px;
    color: @text-color;
    visibility: hidden;
  }

  .activity-date-accept {
    margin-left: 10px;
  }

  .activity-date-cancel {
    margin-left: 5px;
  }
}

.activity-date:hover {
  i.date-edit {
    color: @text-color;
    visibility: visible;
  }
}

.edit-title-icon{
  i{
    margin-left: 10px;
    visibility: hidden;
  }
}
.edit-activity-type{
  i{
    margin-left: 10px;
    margin-top: 6px;
    visibility: hidden;
  }
}

.edit-title-icon:hover{
  i{
    visibility: visible;
  }
}
.edit-activity-type:hover{
  i{
    visibility: visible;
  }
}
.icons-accept-title{
  i{
    margin-left: 10px;
  }
}
.icons-cancel-title{
  i{
    margin-left: 5px;
  }
}
.ant-select-selection-selected-value{
  .icon-color{
    margin-top: 8px!important;
  }
  .status-icon-container{
    margin-top: 0px;
  }
}
.icon-cancel-type{
  i{
    margin-left: 10px;
    margin-top: 10px;
  }
}
.activity-edit-main-view{
 padding-right: 10px;
}
.res-user-main-view:hover, .activity-edit-main-view:hover, .project-location-edit:hover{
  background-color: lightgray;
  cursor: pointer;
  opacity: 0.9;
  transition: 0.3s;
}
.res-user-main-view{
  margin-right: 10px;
}
.activity-subTitle-date{
  min-width: 85px;
  padding-right: 10px;
  padding-top: 10px;
  display: flex;
}

.timespan-fields-container{
  .icon-cancel-outlined{
    color: @grey;
    cursor: pointer;
  }
  .icon-check-outlined{
    cursor: pointer;
  }
}

.subTitle-date-inputs{
  float: left;
  width: 400px;



  .ant-time-picker{
    width: 400px;
  }
  .ant-calendar-picker{
    width: 125px;
    margin-left: 20px;
  }
}
.subTitle-date-inputs-actions{
  float: left;
  margin-top: 22px;
  i{
    margin-left: 15px;
  }
}
