
.advanced-search-builder-container {
  .advanced-search-edit-end-button-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .add-button {
    cursor:pointer;
    margin-right: 10px;
    border: 1px solid @border-color-base;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    span.anticon.anticon-plus {
      font-size: 11px;
    }
  }
  .hide-content {
    display: none;
  }
}
