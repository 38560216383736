

@media screen and (max-width: 1400px) {
  .calendar-activity-event-card-container .right {
    display: none;
  }
}



.calender-card-container {
  width: 100%;
  min-height: 50px;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20);
  margin-bottom: @padding-md;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .icon-container {
    span{
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .display-persons-card, .left{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .left{
    padding-left: 5px;
    padding-top: 2px;

  }
  .right-time{
    padding-right: 5px;
    padding-top: 2px;
    float: right;
  }
  .right{
    padding-left: 10px;
    padding-top: 2px;
  }

  .top {
    display: flex;
    .card-title {
      padding: 5px 0 0 10px;
      width: 75%;
    }
    .card-time{
      padding: 5px 0 0 10px;
      width: 25%;
    }
    .break {
      flex-basis: 100%;
      height: 0;
    }


  }

  &.GREEN{
    background-color: @green-rgba-10;
    border-left: 6px solid @green;
  }
  &.BLUE{
    background-color: @blue-rgba-10;
    border-left: 6px solid @blue;
  }
  &.ORANGE{
    background-color: @orange-rgba-10;
    border-left: 6px solid @action-color;
  }
  &.GRAY{
    background-color: @gray-rgba-10;
    border-left: 6px solid @grey;
  }
  &.DARKGREEN{
    background-color: @dark-green-rgba-10;
    border-left: 6px solid @dark-green;
  }
  &.RED{
    background-color: @red-rgba-10;
    border-left: 6px solid @red;
  }
  &.YELLOW{
    background-color: @yellow-rgba-10;
    border-left: 6px solid @yellow;
  }



  .bottom {
    .card-subTitle {
      font-size: 12px;
      padding: 0 0 0 10px;
      color: @light-grey
    }
    .card-count {
      padding-left: 10px;
      color: @light-grey;
      margin-top: 5px;
      margin-bottom: 10px;

      .calendar-card-badge {
        font-size: 12px;

        &.blue { color: @blue; }
        &.grey { color: @grey; }
        &.red { color: @red; }
        &.yellow { color: @yellow; }
        &.green { color: @green; }
      }

      .calendar-card-badge img {
        height: 10px;
      }
    }
  }
}

.fc-day-view{
  .right-time{
    padding-right: 20px;
    padding-top: 2px;
    float: right;
  }
}
