
.colored-circle-container {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.small {
    height: 18px;
    width: 18px;
  }
  &.medium {
    height: 24px;
    width: 24px;
  }
  &.large {
    height: 32px;
    width: 32px;
  }

  &.primary {
    background-color: @primary-color;
  }

  &.default {
    border: 1px solid @border-color-base;

  }

}
