
.button-component-container {

  .ant-btn {
    &.button-type-action {
      background-color: @action-color;
      color: @white;
      border-color: @action-border-color;
    }
    &.button-type-warning {
      background-color: @red;
      color: @white;
      border-color: @red-rgba-10;

      &:focus, &:hover {
        border-color: @red-rgba-70;
      }
    }
  }
}
