.person-view-search-center-drawer-container {
  @drawerWidth: 465px; // Needs to match the one provided to EntitiesSearchDrawer.js:Drawer
  @containerWidth: clamp(280px, calc(~"75vw - @{drawerWidth}"), 480px);

  .container {
    animation: fadeIn 250ms;
    transition: opacity 5000ms ease-in;
    transition-delay: 100ms;
    border-radius: 10px;
    height: 85%;
    overflow-y: hidden;
    width: @containerWidth;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc((100vw - 800px) * -1);
    right: @drawerWidth + 80px;

    .container-form {
      height: 100%;
      position: relative;

      .droppable-container {
        height: 100%;
        .clemScrollBar(calc(~"100% - 155px"));
        //overflow-y: scroll;
        .dragging {
          width: 304px;
          padding: 0 10px;
          border: 2px solid @primary-color;
          border-radius: 10px;
          background-color: white;
          .criteria-input-container {
            background-color: fade(#fff, (0*100));
          }
        }

        .criteria-input-container {
          background-color: white;
          padding: 14px 25px;
          border-bottom: solid 1px @border-color;
        }

        //noinspection ALL
        .drag-icon {
          font-size: 18px;
          cursor: move; /* fallback if grab cursor is unsupported */
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;
        }

        /* Apply a "closed-hand" cursor during drag operation. */
        //noinspection ALL
        .drag-icon:active {
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
        }
      }
    }
  }
}


.criteria-overview-container{

  width: 100%;
  background-color: @background-color-grey;
  text-align: center;
  padding: 30px 20px 0 20px;
  &.default{
    height: 100%;
    position: absolute;
    .text{
      margin: auto;
      padding-top: 90px;
      text-align: center;
      padding-left: 40px;
      padding-right: 40px;
    }

  }
  &.limit{
    height: 145px;
    .text{
      margin: auto;
      text-align: center;
      padding-top: 40px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

}

.ant-modal-content .entities-search-index-container{
  background-color: @body-background;
  padding:20px;
  .label, .title.bounding-box, .add-countries.bounding-box span{
    color:@white;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
