
.activity-create-drawer-container {
  .ant-drawer-content-wrapper {
    width: clamp(680px, 80%, 920px) !important;
  }
}

.activity-create-drawer-inner-container {
  margin: 0 @padding-lg;
}
