
.admin-entity-custom-fields-container {

  .grid-item {
    width: 20%;
    height: 68px;
    cursor: pointer;

    &.flex {
      display: flex;
      align-items: center;
      column-gap: @padding-sm;
    }
  }

}
