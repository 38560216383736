
.admin-section-container {
  border-bottom: 1px solid @border-color;
  padding: 12px 0 18px 8px;

  > .section-content {
    padding: 4px 0 0 24px;
  }

}
.custom-field-select-grid{

  .center{
    display: flex;
    align-items: center;
    justify-content: center;
    .button-cancel{
      margin-top: 2px;
    }
  }
}