
.quick-action-button-container {
  
  margin: 2px;
  .ant-btn {    
    &.action-to-take {
      background-color: @action-color;
      color: @white;
      &:hover, &:active, &:focus {
        border-color: @action-border-color;
      }
    }
  }
}
