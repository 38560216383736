.activity-location {
  float: left;
  width: 100%;

  .activity-address {
    float: left;
    width: 100%;

    .remove-site-map{
      float: right;
    }

    h3 {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  .activity-map {
    width: 100%;
  }
}