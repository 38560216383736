
.person-affiliations-table-container {

  .table-top {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 15px;
  }

}
