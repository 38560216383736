
@import "./AdminGridRightFieldsConfigurator/_adminGridRightFieldsConfigurator";

.detailed-grid-layout-container {
  width: 100%;
  height: 100%;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 2px @green-rgba-30;
    }

    70% {
      box-shadow: 0 0 0 5px @green-rgba-50;
    }

    100% {
      box-shadow: 0 0 0 2px @green-rgba-30;
    }
  }

  &.active {
    //transform: scale(1);
    animation: pulse 2s infinite;
    box-shadow: 0 0 0 2px @green-rgba-30;
  }
}
