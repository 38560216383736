
.dcr-create-container {
  margin-top: 10px;

  .contact-card-container {
    margin-bottom: 20px;
  }

  .dcr-button-submit {
    display: inline-block;
  }

  .dcr-button-container {
    display: grid;
    grid-gap: @medium;
    justify-content: center;
    grid-auto-flow: column;
    justify-self: start;
    margin: @medium 0;
    clear: both;

  }

  .step-visited {
    color: @primary-color;
  }
}

.icon-visit {
  fill: @primary-color;
}

.link {
  margin-bottom: @small;
}

.clear {
  clear: both;
}
.dcr-details-container {
  .dcr-own-module-upsell-1{
    text-align: center;
    margin-top: 15px;
  }
  .dcr-tab-history-container {
    .history_Comment {
      font-style: italic;
    }
    .ant-timeline-label {
      float: left;
      width: 100%;
    }
    .history-comment {
      font-size: 12px;
    }
    .ant-tag-has-color {
      position: absolute;
      margin-left: 5px;
    }
  }
  .related-dcr-tab {
    padding-left: 35px;
    padding-top: 10px;
    .button-take-action {
      text-align: end;
    }
  }

}

.dcr-group-dcr-item .ant-space-align-center div{
  margin-top: 10px;
  margin-bottom: 5px;
}

.dcr-details-container .detail-view-label{
  font-weight: bold;
}