
.entities-search-drawer-drop-area {
  height: 0;

  &.hide {
    visibility: hidden;

  }


  @colorFrom: fade(@light-grey, (.1*100));
  @colorTo: fade(@light-grey, (.5*100));
  @keyframes change-color {
    from { background-color: @colorFrom }
    to { background-color: @colorTo }
  }

  @keyframes change-color-back {
    from { background-color: @colorFrom }
    to { background-color: @colorTo }
  }

  .abc {
    height: 100vh;
    position: fixed;
    top: 0;

    .ant-upload {
      z-index: -1;
      background-color: @colorFrom;
      &.on-hover {
        animation: change-color 0.2s linear;
        background-color: @colorTo;
      }

      p {
        padding: @padding-md;
      }
      .icon {
        display: flex;
        justify-content: center;
        padding: @padding-md;
        align-items: center;
        font-size: clamp(24px, 20vh, 128px);
        color: fade(@error-color, (0.7*100));
      }
    }
  }
}
