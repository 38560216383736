.advance-saved-search-tree {
  .ant-tree-node-selected {
    color: rgba(0,0,0, .65)!important;
    border-left: 4px solid @primary-color;
  }
  .ant-tree-treenode-selected {
    &::before {
      background: white!important;
    }

  }
}

.simple-saved-searches-container {
  width: 100%;

  .search {
    border: none;
    border-bottom: 1px solid @border-color-base;
    border-radius: 0;
    padding: @padding-xss @padding-xs @padding-xss @padding-md;

    .ant-input-search-icon::before {
      border-left: none;
    }
  }

  .saved-advance-searches {
    display: inline-flex;
    justify-content: space-between;
    width: calc(100% - 24px);
    .default-query {
      font-size: 12px;
      padding: 0 5px;
      color: @primary-color;
      border: 1px solid @primary-color;
      border-radius: 5px;
    }
    .search-action-buttons {
      visibility: hidden;
      display: flex;
      gap: 5px;
      .action-button {
        padding: 0 10px;
        border-radius: 5px;
        min-height: 30px;
        &:hover {
          color: @primary-color;
          border: 1px solid @primary-color;
        }
      }
    }

    &:hover {
      .search-action-buttons {
        visibility: visible;
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: @padding-xss @padding-xs @padding-xss @padding-md;

    &.selected {
      background-color: fade(@light-green, (.5*100));
      box-shadow: 0 0 @padding-sm 0 fade(@light-green, (.5*100));
    }

    .left {
      color: @text-color;
      opacity: 0.9;
    }

    .right {
      color: @text-color-secondary;
      opacity: 0.75;
    }
  }
}
