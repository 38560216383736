
.entities-search-container {
  display: table;
  width: 100%;

  > .main-padding {
    padding: 40px 30px 30px 30px;
  }

  .white-container {
    > .search-container {
      width: 100%;
    }
  }

}
