@import "_mainViewInlineEdit";

.main-view-container {

  &.main-position {
    margin: auto;
    transition: margin ease-in-out 150ms;

    &.open {
      margin-left: 220px;
      margin-top: 20px;
    }

    &.collapse {
      margin-left: 80px;
      margin-top: 20px;
    }

    &.activities-overview-container {
      .main-view-bounding-background {
        display: grid;
      }
    }

    .main-view-bounding-background {
      background-color: @main-view-bounding-background;
      border-radius: 10px;
      margin-top: 5px;
      padding: 25px;
    }

  }

  &.main-padding.no-breadcrumbs {
    padding: @main-view-padding-size;
  }
  &.main-padding.has-breadcrumbs {
    padding: @main-view-padding-size - 20px @main-view-padding-size @main-view-padding-size @main-view-padding-size;
  }

  .content-top {
    width: 100%;

    .title-element {

      .title {
        color: @heading-color;
        padding: 10px 0 20px 0;
        display: flex;
        justify-content: center;
        .fontSize(@font-size + 1);
        font-weight: 500;

      }

      .ant-space-item {
        h1, h2, h3 {
          line-height: 1;
        }

        height: 100%;
      }
    }

    h1 {
      float: left;

      .edit-field > input {
        .fontSize(22px);
        color: #000;
        font-weight: 300;
        padding: 1px 4px;
        width: 100%;
      }
    }

    .top-actions {
      text-align: right;
    }
  }

  .white-container {
    float: left;
    width: 100%;
    background: #fff;
    padding: 40px 30px;
  }

  .breadcrumb-item, .ant-breadcrumb-separator {
    color: @primary-color;

    &.clickable {
      cursor: pointer;

      &:hover {
        color: tint(@primary-color, 80%);
      }
    }
  }

}

.site-view-container, .activity-view-container2, .dcr-view-container, .list-view-container2 {
  background-color: @main-view-background-color;
  height: 100%;

  .title {
    color: white !important;
  }
}
