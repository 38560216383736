
.dashboard-grid-top-actions-container {


  margin: 15px @dashboard-grid-horizontal-padding 0 @dashboard-grid-horizontal-padding;

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .edit-mode-warning { margin: @padding-md 0; }

}
