
.calendar-user-selector-container {
  width: 320px;


  .list-container {
    .clemScrollBar(320px, 8px);

    .list-item {
      cursor: pointer;

      &.active {
        background-color: @green-rgba-30;
      }

      &:hover {
        background-color: @green-rgba-50;
      }
    }
  }
}
