
.label-with-count-container{
  min-height: 35px;
  padding-top: 7px;
  border-bottom: 1px solid @border-color;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  &.active{
    background-color: @green-rgba-30
  }

  .text {
    cursor: pointer;
  }
  .icon{
    color: @primary-color;
    text-align: right;
  }
  .count{
    text-align: right;
  }
  &:last-child {
    border-bottom: none;
  }
}
