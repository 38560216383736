
.sortable-elements-container {

  @keyframes sortable-pulse-loading {
    0% { box-shadow: 0 0 0 0 @green-rgba-30; }
    45% { box-shadow: 0 0 0 1px @green-rgba-50; }
    70% { box-shadow: 0 0 0 4px @green-rgba-70; }
    95% { box-shadow: 0 0 0 1px @green-rgba-50; }
    100% { box-shadow: 0 0 0 0 @green-rgba-30; }
  }

  .sortable-element {
    .grabbable();

    &.standard {
      border: 1px solid @primary-color;
      border-radius: 5px;
      background-color: @white;
      height: 100%;
      width: 100%;

      &.size-default {
        padding: 15px;
      }

      &.size-small {
        padding: 6px 8px;
      }
    }

    &.loading {
      box-shadow: 0 0 0 0 @green-rgba-30;
      animation: sortable-pulse-loading 3s infinite;
    }
  }

}
