
.attribute-label-container {

  min-height: 35px;
  border-bottom: 1px solid @border-color;
  width: 100%;

  align-items: center;

  @media screen and (min-width: 1026px) {
    display: flex;
  }

  .label {
    width: 150px;

    @media screen and (max-width: 1025px) {
      margin-top: 5px;
    }
  }
  .value {
    flex: 1;

    @media screen and (max-width: 1025px) {
      padding-left: 10px;
      margin-bottom: 5px;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}
