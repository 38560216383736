
.product-segmentation-code-card-container {
  display: flex;
  align-items: center;
  flex-flow: column;
  padding: 10px 10px 10px 0;

  .card {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    padding: 10px;
    text-align: center;
    display: flex;
    position: relative;
    flex-flow: column;
    flex-wrap: wrap;

    .pill-wrapper {
      border-radius: 50%;
      background-color: #f5f5f5;
      display: inline-block;
      text-align: center;
      position: relative;
      align-self: center;
      margin-bottom: 15px;
      width: 45px;
      height: 45px;
      line-height: 45px;

      .badge {
        position: absolute;
        right: -6px;
        top: -6px;
        width: 22.5px;
        height: 22.5px;
        background-color: @blue;
        line-height: 22.5px;
        text-align: center;
        color: white;
        border-radius: 50%;
        font-weight: 600;
      }
    }
  }

  .remove {
    cursor: pointer;
    color: @blue;
    padding-top: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.product-segmentation-code-add-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
