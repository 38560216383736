
.field-label-group-container {

  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: auto minmax(10px, auto);

  @media screen and (max-width: 1025px) {
    grid-template-columns: auto;
  }

  > .field-label, .field-value {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid @border-color;
    padding-left: 10px;

    &.field-value {
      flex: 1;
      font-weight: bold;
      overflow-wrap: anywhere;
    }

    @media screen and (max-width: 1025px) {
      &:nth-child(odd) { border-bottom: none; } // Remove borders from labels
      &.attribute-label { padding-top: 8px; }
      &.attribute-value { padding-bottom: 8px; }
    }

    @media screen and (min-width: 1026px) {
      padding: 8px 0 8px 10px;
    }

    // Remove borders from last line
    &:nth-last-child(1), &:nth-last-child(2) { border-bottom: none; }

    ////////////////////
    // Nested styling //
    ////////////////////
    .edit-icon {
      visibility: hidden;
      padding: 0 4px;
      &.icon-hovering {
        visibility: visible;
      }
    }
    .delete-icon, .loading-icon {
      padding-left: 6px;
    }
  }

}
