.product-dispay-fields {
  float: left;
  width: 200px;
  margin: 50px 50px 0 0;
  .field-label {
    font-weight: bold;
    .fontSize(14px);
    color: black;
  }
}
.product-tab-container{
  margin-top: 25px;
}