
#create-site-container {

  .addresses {
    .ant-space {
      width: 100%;
      .ant-space-item:first-child {
        width: 100%;
      }
    }
  }
}
