.advance-input-data-container, .date-timespan-form-container.activity-timespan {
  padding: 6px 12px;
  height: 38px;
  line-height: 1.5715; // to have consistent input height
  border-radius: 5px;
  width: 100%;
  font-size: 14px;
  background: @white;
  border: 1px solid @border-color-base;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.advance-input-data-container {
 background: @light-grey-rgb;
}
.advance-input-only-container {
  font-size: 16px;
  padding: 6px 12px;
  height: 38px; // to have consistent input height
  input{
    font-size: 16px;
  }
  //border-bottom: 1px solid @border-color;
  &.ant-form-item {
    margin-bottom: 0;
  }

  //&.ant-input-affix-wrapper-focused{
  //   border-left:none !important;
  //   border-top:none !important;
  //   border-right:none !important;
  // }

  .apb-icon{
    color: @green;
  }
/*  &.ant-input-affix-wrapper, &.ant-input-affix-wrapper:focus{
    border-left:none !important;
    border-top:none !important;
    border-right:none !important;
    font-size: 16px;
    background:none !important;
    box-shadow: none;
    input{
      background:none !important;
      font-size: 16px;
      &:focus{
        border-left:none !important;
        border-top:none !important;
        border-right:none !important;
      }
    }
  }*/


}
