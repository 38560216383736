.bulkPersProj-new-project-step-container {
  margin: 15px 0;

  .label-row{
    padding-top: 5px;
  }

  .ant-row-flex{
    margin-bottom: 10px;
  }



}

.button-container{
  margin-top: 25px;


  button:not(:first-child){
    margin-left: 15px;
  }
}

