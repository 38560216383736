
.advance-card-container {
  display: flex;
  align-items: center;
  margin-top:5px;
  float: left;
  margin-bottom: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
  border-radius: 5px;
  background-color: white;
  margin-right: 30px;
  cursor: pointer;
  width: 100%;
  max-width: 500px;
  padding: 8px;
  &:hover{
    box-shadow: 0 0 12px 0 @green-rgba-50;
  }
  .note-icon {
    svg{
      margin-top: 17px;
      margin-left: 19px;
    }
  }
  .note-creation-details {
    display: flex;
    justify-content: space-between;
  }
  .note-description{
    h4{
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 2px;
      margin-left: 10px;
    }
    .text{
      font-size: 12px;
      margin-left: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }



  }
  .card-info{
    width: calc(100% - 46px);
    margin-left: 10px;
    h4{
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 2px;
    }
    h5{
      font-size: 10px;
    }
    .text {
      p {
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
