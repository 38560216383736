
.list-detail-view-content-container {

  .data-values {
    display: grid;
    grid-column-gap: @padding-xss;
    @media (max-width: @media-sm) { grid-template-columns: repeat(1, fit-content(40%) 1fr); }
    @media (min-width: @media-sm) { grid-template-columns: repeat(2, fit-content(25%) 1fr); }

    .value {
      font-weight: bold;
    }
  }

  .right-share-actions {
    display: flex;
    justify-content: flex-end;
    .element {
      margin-left: 20px;
      text-align: center;
    }
  }

  .remove-from-list {
    cursor: pointer;
  }

  .table-container {
    margin-top: 20px;
  }

  .list-affiliations {
    margin-top: 50px;

  }

  .user-details {
    text-align: right;
    padding-bottom: 5px;

    .user-information {
      margin-top: 10px;
      margin-right: 10px;
    }

  }

  .import-lists {
    display: flex;
    align-items: center;

    button.btn-sm {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      .export-label-icon {
        width: @small;
        margin-top: 2px;
        float: left;
      }

      .export-label-text {
        min-width: 75px;
      }
    }

    .ant-select-selector {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
      border-left: none;
    }
  }


  .list-field-container {
    width: 100%;

    .right-rules {
      margin-top: 50px;
    }

    > div {
      float: left;

      .list-display-fields {
        float: left;
        width: 200px;
        margin: 50px 50px 0 0;

        .field-label {
          font-weight: bold;
          .fontSize(14px);
          color: black;
        }

      }

      .list-affiliations {
        margin-top: 50px;

      }
    }


  }


  //LIST VIEW
  .lists-container {
    .dynamic-label-true {
      float: right;
      font-size: larger;

      img {
        height: 20px;
        margin-right: 5px;
      }
    }
  }

  .dynamic-label-false {
    display: none;
  }
}
