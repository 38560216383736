@import "_textArea";
@import "_advanceInputOnly";
@import "_glowButton";

.input .icon-container{
  img{
    max-height: 14px;
  }
}
.input .ant-input-suffix .icon-container{
  img{
    max-height: 14px;
  }
}

.APB_DETAILS{
  .ant-picker{
    width: 100%;
  }
}

.advance-input-container{
  margin-bottom: 20px;

  .ant-form-item {
    margin-bottom: 0;
  }
  .details-page-section-container {
    padding-bottom: unset;
  }

  .ant-select-selector{
    /*border:none !important;
    background:none !important;
    box-shadow: none!important;*/
    font-size: 16px;
    cursor: pointer;
  }

  .ant-input-affix-wrapper-focused{
   /* border:none !important;*/
  }

  .apb-icon{
    color: @green;
  }
  .ant-input-affix-wrapper, .ant-input-affix-wrapper:focus{
    /*border:none !important;*/
    font-size: 16px;
    padding: 6px 12px;
    /*background:none !important;
    box-shadow: none;*/
    input{
      /*background:none !important;*/
      font-size: 16px;
      &:focus{
       /* border:none !important;*/
      }
    }
  }

  .activity-display-value{
    cursor: pointer;
    &:hover{
      -moz-transition: all .5s ease-in;
      -o-transition: all .5s ease-in;
      -webkit-transition: all .5s ease-in;
      transition: all .5s ease-in;
      background-color: @gray-rgba-30;
    }
  }
  .ant-select-selection-item{
    cursor: pointer;
  }

  .advance-input-label{
    font-size: 16px;
    margin: 5px 0 10px 10px;
    display: flex;
   // border-bottom: 1px solid @border-color;

    &.smaller{
      font-size: 14px;
    }
    .advance-label-suffix{
      float: right;
    }

    .add-button-absolute {
      position: absolute;
      top: 0;
      right: 0;
    }

    .activity-card-notes-list {
      height: 161px;
      overflow-y: auto;
      width: 100%;
      padding: 0 15px;
    }
    .activity-card-product-list {
      display: flex;
      flex-wrap: wrap;
      height: 150px;
    }

    .border-when-empty {
      border: 1px dashed @light-grey;
      border-radius: 5px;
    }

    .activity-card-notes-list, .activity-card-product-list {
      margin-top: 15px;
      overflow-y: auto;
      width: 100%;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: @light-grey-rgb;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: @primary-color;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: @green-rgba-70;
    }
  }

  }
  .advance-input-header{
    color: @light-grey;
    font-weight: 500;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
