
.label-with-icon-container {
  cursor: pointer;
  @media screen and (min-width: 1080px) {
    min-height: 35px;
    padding-top: 7px;
  }
  @media screen and (max-width: 1080px) {
    min-height: 45px;
    padding-bottom: 10px;
    padding-top: 12px;
  }


  border-bottom: 1px solid @border-color;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;

  &.active {
    background-color: @green-rgba-50
  }

  .text {
    width: 90%;
  }

  .icon {
    text-align: right;
    color: @primary-color;
  }

  .count {
    width: 10%;
    float: left;
  }
}
