
.search-condition-builder-container {
  display: flex;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: unset;
  }

  .above {
    display: flex;
    justify-content: space-between;
    color: @primary-color;
    .anticon {
      font-size: 16px;
    }
  }

  .select {
    .ant-select {
      width: 100%;
    }
  }

  .new-group-add-button-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}

.select-condition-dropdown-renderer-container {
  .bottom {
    border-top: 1px solid @border-color;
    padding: @padding-sm @padding-xs @padding-xs @padding-xs;
    display: flex;
    justify-content: flex-end;
  }
}

.action-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}
