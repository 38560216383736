
// TODO: Beautify and fix for cross-browsers.
.scrollBar(@height) {
  max-height: @height;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px @light-grey;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px @light-grey;
  }
}

.clemScrollBar(@height, @width: 12px) {
  max-height: @height;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: @width;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: @primary-color;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
  }
}
