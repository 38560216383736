
.icon-color {
  .fontSize(64px);

  &.color-green {
    color: @green;
  }
}

.icon-container {
  display: flex;
  align-items: center;
  column-gap: @padding-xss;

  &.primary{
    color: @primary-color;
  }
  img {
    // Default height
    height: 20px;
  }

  .icon-in-progress, .icon-done, .icon-cancel, .icon-in-active, .icon-open {
    margin-right: @micro;
    .fontSize(@font-size);
  }

  .icon-in-progress, .icon-done {
    color: @green;
  }

  .icon-cancel {
    color: @cancel-red;
  }

  .icon-in-active {
    color: @grey;
  }

  .icon-open {
    height: 16px;
    vertical-align: text-top;
  }

  &.clickable {
    cursor: pointer;
  }
}
