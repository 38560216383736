@import "./ListDetail/_listDetails";
@import "./ListDetail/_listDynamicRules";
@import "./components/_listDetailsStar";

.content-section-person-container {
  border-top: solid 2px @border-color;
  position: relative;

  .content-section-header {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 1px;
    height: 25px;
    border: solid 2px @border-color;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    background-color: @background-color-grey;
    top: -15px;
    left: 0;
    right: 0;
    margin: auto;
    width: 150px;
  }

  .clear {
    clear: both;
  }
}

.dynamic-label-true {
  float: right;
  font-size: larger;

  img {
    height: 20px;
    margin-right: 5px;
  }
}

.dynamic-label-false {
  display: none;
}

.project-create-container{

  textarea{
    height: 150px;
  }

}

.list-status-icon {


  i {
    padding-left: 5px;
  }
}
.sort-fav-lists{
  .fav-line {
    border-left: 4px solid @light-green;
  }
}
.static-list-container{
  margin-left: 20px;
  margin-right: 20px;

  .label{
    margin-left: 11px;
    &:after{
      content: ":";
    }
  }
  .input{
    margin-top:10px
  }
}

.static-list-create-container{
width: 500px;
}
.steps-action{
 margin: auto;
  text-align: center;
  margin-top: 15px;
}
