.search-condition-custom-input-builder {
  .input-builder-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
  }
  .input-builder-element-container {
    .input-builder-element-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
       .element-label, .element-item {
         flex: 1
       }
    }
    .element-value-options {
      margin-top: 20px;
    }
  }
}