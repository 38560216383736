
// MARK: Using z-index 1250, because when using inside ant modals it needs to be above. Ant Modal Defaults to 1000

.draggable-table-container-row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 1250;
}

.draggable-table-container-row-dragging td {
  padding: 16px;
  z-index: 1250;
}

.draggable-table-container-row-dragging .drag-visible {
  visibility: visible;
  z-index: 1250;
}
