
.simple-search-overview-header-container {
  padding: @padding-md @padding-lg;
  border-bottom: 1px solid @border-color-base;
  background-color: @darker-grey;


  .heading {
    color: @white;
    .fontSize(@font-size + 1);
    font-weight: 500;

    display: flex;
    justify-content: space-between;
  }

  .ant-form-item {
    margin: @padding-sm 0;
  }

  .search-name {
    //border: 3px solid red;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: space-between;
      > div {
        padding-right: @padding-sm;
      }
    }
    .right {
      //border: 1px solid red;
    }
  }
}

.simple-search-overview-header-menu-container {
  .delete-search-icon {
    color: @error-color;
  }
}
