
.activity-heading-details-container {

  .top {
    display: flex;
    align-items: center;

    > div {
      margin: 0 5px;
    }
    .title {
      color: @heading-color;
      padding: 10px 0 20px 0;
      display: flex;
      .fontSize(@font-size + 1);
      font-weight: 500;

      &.edit-access:hover {
        background-color: lightgray;
        cursor: pointer;
        opacity: 0.9;
        transition: 0.3s;
      }
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }


  .timestamps {

    border: 2px solid red;

  }

  .activity-detail-margin-fix {
    margin-left: 10px;
  }


}
