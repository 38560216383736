
.status-icon-container {
  .color-fade(@color) {
    background: fade(@color, (.3*100));
    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      margin: 3px;
      border-radius: 50%;
      background-color: @color;
    }
  }
  .color-icon(@img) {
    background: url("../../../img/png/@{img}") no-repeat left;
    &:after {
      margin-left: 10px;
      padding-left: 20px;
    }
  }

  float: left;
  min-height: 16px;
  position: relative;
  padding-left: 5px;

  .icon-label {
    padding-top: 1px;
    padding-left: 21px;
  }

  .icon-color {
    float: left;
    margin-top: 2px;
    width: 16px;
    height: 16px;
    position: relative;
    background: fade(@grey, (.3*100));
    border-radius: 50%;
    margin-right: 5px;

    // Color types
    &.Green { .color-fade(@green) }
    &.Light-Green { .color-fade(@light-green) }
    &.Dark_Green { .color-fade(@dark-green) }
    &.Blue { .color-fade(@blue) }
    &.Gray { .color-fade(@grey) }
    &.Red { .color-fade(@red) }
    &.Reed { .color-fade(@red) }
    &.Orange { .color-fade(@action-color) }
    &.Yellow { .color-fade(@yellow) }

    // via Codes
    // TODO: @HJU Remove these?
    &.call_visit { .color-fade(@activity-visit) }
    &.call { .color-fade(@activity-call) }
    &.canvas_call { .color-fade(@activity-canvas_call) }
    &.group_call { .color-fade(@activity-group_call) }
    &.congress { .color-fade(@activity-congress) }
    &.symposia { .color-fade(@activity-symposia) }
    &.workshop { .color-fade(@activity-workshop) }
    &.meetings { .color-fade(@activity-meetings) }
    &.service_visit { .color-fade(@activity-service_visit) }
    &.double_visit { .color-fade(@activity-double_visit) }

    &.OPEN { .color-icon('openStatus.png') }
    &.INACTIVE { .color-icon('closed.png') }
    &.DONE { .color-icon('done.png') }
    &.IN_PROGRESS { .color-icon('inProgress.png') }
  }
}
