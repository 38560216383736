
.admin-grid-right-fields-configurator-container {
  width: 100%;
  .item {
    display: flex;
    justify-content: space-between;
    border: 1px solid @primary-color;
    border-radius: 5px;
    background-color: @white;
    padding: 15px;
    height: 100%;
    width: 100%;

    .left {

    }

    .right {
      display: flex;
      column-gap: @padding-sm;
      .settings {
        //border: 1px solid blue;
      }

      .remove {
        //border: 1px solid green;
      }

      .amount-of-options {
        //border: 1px solid purple;
      }
    }




  }

}
