
.input-textarea-container {
  position: relative;
  display: inline-block;

  width: 100%;


  textarea {
    padding-right: 22px;
  }

  .top-right {
    position: absolute;
    top: 0;
    right: 0;

    margin: 5px;
    font-size: 16px;
    color: rgba(0, 0, 0, .4);
  }
}

.input-checkbox-container{
  text-align: center;
  .input-checkbox-label{
    margin-right: @micro;
  }
}



