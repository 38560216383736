
.entities-search-table-results-container {
  padding: 20px;
  border-radius: 10px;
  background-color: white;

  .table-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    .left {
      flex-shrink: 0;
    }

    .middle {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}

.mass-assign-modal {
  .mass-assign-container {
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f5f5f5;

    .mass-assign-content-container{
      background-color: @white;
    }

    .mass-assign-steps-container {
      padding: 15px;

    }

    .mass-assign-content-container{
      padding: 15px;

    }

    .steps-action{
      text-align: center;
      padding: 20px 0;
    }
    .mass-assign-type-description{

    }
    .mass-assign-content {
      background-color: @white;
      box-shadow: 0 5px 11px 4px rgba(0, 0, 0, 0.09);
      padding: 13px 8px;

      margin-top: 10px;
      border-radius: 5px;
      border: 1px solid @border-color;

      .mass-assign-row {
        border-bottom: 1px solid @border-color;
        padding: 10px 0;

        .mass-assign-heading-name div {
          cursor: pointer;
          border-radius: 5px;
          position: absolute;
          padding: 0 5px;
        }

        .mass-assign-heading-name div:hover {
          background-color: #f5f5f5;
        }
      }

      .mass-assign-row:last-child {
        margin-bottom: 20px;
      }


    }


  }

  .ant-modal-header {
    border-bottom: solid 3px @primary-color;
  }

  .ant-modal-body {
    padding: 0;
  }
}
