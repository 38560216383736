@import "./_calendarTopMenu";
@import "./_calendarActivityEvent";
@import "./_calendarUserSelector";

.calendar-view-container {
  padding-bottom: 30px !important;
  .main-view-bounding-background {
    margin-right: 30px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: @padding-xss;
  }
}

.work-week-calendar-container {
  .fc-day-today {
    background: none !important;
  }

  .fc-scrollgrid {
    border: none;

    .fc-timegrid-axis {
      border: none;
    }
  }

  .fc-col-header-cell {
    border-left: none;
    border-right: none;
    border-top: none;
  }

  .fc-scroller {
    .clemScrollBar(100%, 8px);
  }


  .fc-timegrid-slot-label {
    //vertical-align: top;
    border-left: none;
  }

  .fc-collgrid-sync-inner {
    width: 100%;
  }

  .fc-col-header-cell-cushion {
    width: 100%;
    color: black;

    > div {
      display: flex;
      justify-content: center;

      span {
        margin-left: auto;
      }
    }

    .ant-badge > sup {
      background: @primary-color;
    }
  }

  .fc-day-today {
    .fc-col-header-cell-cushion {
      color: @primary-color;
    }
  }
}

.calendar-event-container {
  border: none;
  background-color: unset;
  width: 100%;
}

.fc-view-container *, .fc-view-container ::after, .fc-view-container ::before {
  box-sizing: border-box !important;
}

.calendar-dashboard-section {
  .main-view-container {
    margin-left: 0 !important;
    padding: 0 !important;
  }

  .calendar-th-container {
    color: @light-grey;
    font-weight: 100;

    .th-header {
      color: black;
      font-weight: 600;
      margin-right: 5px;
    }

  }
}


.calendar-container {
  display: flex;

  .calendar-container-full {
    width: 100%;
  }

  .calendar-container-modified-width {
    width: 80%;
  }

  .activity-planning-section {
    width: 20%;
  }

  .activity-planning-container {
    margin: 0 15px;

    .planning-cards {
      padding: 5px;
    }


  }

}
