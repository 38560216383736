
.dashboard-card-container {
  border-radius: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 11px 4px rgba(0, 0, 0, 0.09);

  .breadcrumb-label {
    font-size: 15px;
    font-weight: 500;

    &.clickable {
      cursor: pointer;
      transition: color .3s;

      &:hover {
        color: rgba(0,0,0,.65);
        transition: color .3s;
      }
    }
  }
  .ant-radio-group {
    min-width: 110px;
    padding-left: 10px;
  }

}
