
.main-view-inline-edit {

  .main-content {

    h2 {
      color: @heading-color;
    }
    span {
      color: @text-color-secondary;
    }



    &:hover {
      background-color: lightgray;
      cursor: pointer;
      opacity: 0.9;
      transition: 0.3s;
    }
  }

}
