
.admin-grid-configuration-right-container {
  border-left: 1px solid @border-color;
  padding: @padding-xss;

  .crumbs {
    margin: 0 0 8px 2px;
  }


  .title-and-children {
    display: flex;
    flex-direction: column;
    align-items: center;


    .icon-container{
      display: block;
    }
    .heading{
      font-size: 12px;
    }
  }
}
