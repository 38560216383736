
.list-details-star-container {
  font-size: 18px;

  &.pin { /* Just use the default black color */
  }

  &.favorite, &.standard {
    color: @green;
  }

  web /src/ js /browse/ list /components/ _listDetailsStar.less
  &.locked {
    cursor: default;
  }
}
