@import "_affiliationCard";

.card-container {

  width: 99%;
  scrollbar-color: @primary-color #fff ;

  @media screen and (max-width: @min-desktop-width) {
    margin-right: 15px;
  }
  @media screen and (min-width: @min-desktop-width) {
    margin-right: 50px;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: @primary-color;
  }
  ::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
    /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/

  }


  a.has-consent {
    color:@green
  }
  a.link{
    color:@heading-color
  }

  .card-body {
    background-color: @white;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    border-radius: 5px;
    border: 1px solid @primary-color;

    padding: 15px 15px;

    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20);
    .gradientback{
      left: 3px;
      right: 12px;
      position:absolute;
      bottom:6px;
      margin-left: 20px;
      padding-right: 10px;
      margin-right: 12px;
      height: 60px;
      background: @white;
      -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.3)), to(rgba(255,255,255,1)))

    }

    &.size-small {
      height: 210px;
    }

    &.size-medium {
      height: 300px;
    }

    &.size-large {
      height: 400px;
    }

    .card-title {
      margin-left: 10px;
      font-weight: 600;
      height: 30px;
      display: flex;

      .left {
        flex-grow: 1;
        display: flex;
        align-items: center;
        column-gap: @padding-xs;
        @media screen and (max-width: 1608px) {
          .fontSize(@font-size - 2);
        }
        @media screen and (max-width: 768px) {
          .fontSize(@font-size - 3);
          margin-top: 10px;
          margin-bottom: 10px;
        }
        @media screen and (min-width: 1609px) {
          .fontSize(@font-size + 1);
        }
      }

      &.small-font {
        .fontSize(@font-size);

      }

      .text {
        margin-top: 1px;
        padding-top: 2px;

      }
      .icon {
        margin-top: 1px;
        width: 25px;

        &.back{
          cursor: pointer;
        }
      }
      .badge-container{
        .badge{
          width: 20px;
          margin-left: 10px;
          .icon-container{
            cursor: pointer;
          }
        }
      }

    }

  }

}

.card-drawer-container {
  .ant-drawer-body {
    padding: 15px;
    .text{
      display: flex;
      align-items: center;
      align-content: flex-end;
      padding-bottom: 15px;
      .title {
        font-size: @heading-5-size;
      }

      img{
        float: left;
        margin-top: 3px;
        margin-right: 5px;
      }
    }
  }
}

.card-container.own-fields, .site-detail-cards-container.own-fields{
  max-width: 1191px;
  .card-body{
    padding-left: 25px;
    padding-right:20px;

    .ant-col{
      margin-bottom: 5px;
      padding-right: 5px;
    }
  }
}

