.search-condition-element-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .search-condition-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-condition-header-inputs {
      display: inline-flex;
      column-gap: 10px;
      align-items: center;
      .read-only-value {
        min-height: 24px;
        font-size: 13px;
        padding: 0 7px;
        display: flex;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #d9d9d9;
      }
    }
    .search-condition-header-inputs.header-inputs-display-block {
      display: flex;
      flex-direction: column;
      align-items: unset;
    }

  }
  .search-condition-path-values {
    display: inline-flex;
    grid-gap: 10px;
  }
  .search-condition-path-values.custom-input-height {
    min-height: 270px;
    & .search-condition-element-input {
      position: absolute;
      top: 100px;
    }
  }
/*  .search-condition-element-type, .search-condition-element-property {
    margin-bottom: 10px;
  }*/
  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: -11px;
    width: 8px;
    z-index: 2;
    border: 1px solid @border-color-base;
  }
}