
.entity-search-action-container {
  display: flex;
  justify-content: space-between;
  margin: 28px 0 20px 0;

  .search-inactive{
    width: 150px;
    color:white;
  }
  .search-unplaced{
    width: 160px;
    color:white;
  }
  .search-opted-out{
    width: 160px;
    color:white;
  }

  .extra-menu {
    margin: 0 @padding-md;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }


.search-save, .search-clear{
  width: 100px;
  color: white;
}

  .search {
    background: @green;
  }

  .switch-global-data {
    .ant-switch-checked {
      background-color: @secondary-color;
    }

    .extra-global-label {
      .text {
        .fontSize(@font-size - 2);
        width: 100%;
        text-align: center;
        color: @white;
      }

      .count {
        .fontSize(@font-size - 4);
        min-width: 124px;
        text-align: center;
        color: @white;
      }

      display: inline;
      width: 100px;
      height: 35px;
      color: @text-color-secondary;
      font-size: 12px;
    }
  }
}

