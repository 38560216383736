
.primary-key-icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 4px;
  background: @primary-color;
  border: 1px solid @border-color;

  img.default {
    width: @font-size;
    height: @font-size;
  }
}
