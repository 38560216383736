@charset "UTF-8";

// Structure: https://github.com/HugoGiraudel/sass-boilerplate/blob/master/stylesheets/main.scss


/* ---------------------------

	ANIMATIONS

--------------------------- */

.animate { transition: all 0.2s ease-in-out; }
.animate(@duration: 0.2s) {
  transition: all @duration ease-in-out;
}

.no-animate {
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: color 0 ease-in !important;
  transition: none !important;
}


// TODO: Move somewhere else.
// This is a global class that can be used to make ant tables to
// use the minimum width required for a column to no split text to multiple lines
.apb-antd-table-column-width-no-wrap {
  white-space: nowrap;
}

.apb-transparent-ant-modal {
  background: none;
  border: none;
  box-shadow: none;
  .ant-modal-content {
    box-shadow: none;
    border: none;
    background: transparent !important;
  }
}

// global
*, :after, :before {
  box-sizing: border-box;
}

body {
  font-family: @font-family;
  background-color: @body-background;
  color: @text-color;
  margin: 0;
  font-variant: tabular-nums;
  font-feature-settings: "tnum", "tnum";
}


// 1. Configuration and helpers

// 2. Vendors


// 3. Base stuff


// 4. Layout-related sections


// 5. Components
@import './abstract/_mixins';
@import './abstract/_variables';
@import './abstract/_scrollbar';
@import "../js/components/Form/_forms";
@import "../js/components/Card/_calendar";
@import "../js/components/Card/_card";
@import "../js/browse/person/_persons";
@import "../js/browse/list/_lists";
@import "../js/browse/dcr/_dcr";
@import "../js/browse/product/_products";
@import "../js/browse/site/_sites";
@import "../js/browse/project/_projects";
@import "../js/browse/importer/_importer";
@import "../js/browse/search_old/_entitiesSearch";
@import "./tmp/_top";
@import "./tmp/_activity";
@import "./tmp/_activity-card";
@import "./tmp/_overview";
@import "../js/App";
@import "../js/layout/_navigation";
@import "../js/login/_LoginPage";

@import "../js/browse/activity/_activities";
@import "../js/components/View/_View";
@import "../js/components/Badge/_badge";
@import "../js/components/Popup/_Popup";
@import "../js/components/Icon/_Icon";
@import "../js/components/ContactCard/_contactCard";
@import "../js/components/DisplayFields/_displayFields";
@import "../js/components/StatusIcon/_statusIcon";
@import "../js/components/Table/_table";
@import "../js/components/Badge/_future";
@import "../js/components/Skeleton/_skeleton";
@import "../js/components/Input/_input";
@import "../js/components/ExternalNavLink/_externalNavLink";
@import "../js/components/LiveSearch/_liveSearch";
@import "../js/browse/report/_report";
@import "../js/components/LightCollapse/_lightCollapse";
@import "../js/components/CriteriaInput/_criteriaInputFactory";
@import "../js/components/CustomField/_customField";
@import "../js/components/entitiesSearch/_entitiesSearch";
@import "../js/components/Date/_timespanForm";
@import "../js/components/QuickActions/_quickActionButton";
@import "../js/browse/Calendar/_calendar";
@import "../js/components/Label/_label";
@import "../js/components/Note/_noteDrawer";
@import "../js/components/ColoredCircle/_coloredCircle";
@import "../js/components/ColoredCircle/_primaryKeyIcon";
@import "../js/components/DashboardItem/_dashboardItem";
@import "../js/browse/dashboards/_dashboardView";
@import "../js/components/EntityTabs/_entityTabs";
@import "../js/browse/admin/_admin";
@import "../js/components/DraggableTable/_draggableTable";
@import "../js/components/AnimatedTextError/_animatedTextError";
@import "../js/browse/site/create/_createSite";
@import "../js/browse/sitePerson/_personAffiliationsTable";
@import "../js/components/Button/_apbButton";
@import "../js/components/FieldLabel/_fieldLabelGroup";
@import "../js/components/SortableElements/_sortableElements";
@import "../js/components/ContentSection/_contentSection";
@import "../js/components/DataValidationHistoryLog/_dataValidationHistoryLog";
@import "../js/components/EntityTabs/Cards/_entityTabCards";
@import "../js/components/ConfigureTableColumnSettings/_configureTable";
@import "../js/components/EntityCascader/_entityCasader";
@import "../js/components/RangePicker/_rangePicker";

/*@import "./tmp/old/App";*/
@import "./tmp/old/flags";

// Antd rewrites
@import "./tmp/_antd-fixes";

// 6. Page-specific styles


// 7. Themes

// 8 reusable layout css

@import "layout";


