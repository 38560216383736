
.person-popover-card-container {
  // Reset padding inside popover
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;
    width: 100%;

    .grid-item {
      padding: 10px;
    }

    .left {
      grid-column: 1;
      grid-row: span;

      img {
        padding: 2px;
        //max-width: @large;
      }
    }

    .center {
      grid-column: 2 / 4;
      grid-row: 1;
    }

    .button {
      border-top: 1px solid @border-color-base;
      grid-column: 1 / 3 full;
      grid-row: 2;
    }
  }
}
