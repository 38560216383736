/* ---------------------------

	ACTIVITY

--------------------------- */

.activity-title {
  float: left;

  h1 {
    margin-bottom: 0;
  }

  .activity-type {
    margin: 4px 0 0 10px;
  }

  .activity-date {
    float: left;
    width: 100%;
    .fontSize(15px);
  }
}

.responsible-user-comp {
  position: relative;
  margin-right: 15px;

  .btn-delete{
    margin-left: 5px;
  }

  .responsible-user {
    float: left;
    text-align: right;
    color: @grey;
    height: 25px;
    .lineHeight(15px);
    padding-right: 20px;

    .dropdown-chevron {
      margin-top: 4px;
    }

    .user-details {
      float: left;

      span {
        float: left;
        width: 100%;
        .fontSize(12px);
        color: @light-grey;
        .lineHeight(15px);
      }
    }

  }
}

.contact-person-list, .product-detailing-list {
  float: left;
  width: calc(~"100% + 30px");
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-top: -15px;
}

.activity-notes {
  margin: 20px 0;
  &.large {
    textarea {
      width: 100%;
      min-height: 200px;
    }
  }
  &.medium {
    textarea {
      width: 100%;
      min-height: 100px;
    }
  }
  &.small {
    textarea {
      width: 50%;
      min-height: 75px;
    }
  }

}

.complate-activity {
  float: left;
  width: 100%;
  text-align: center;
}

.person-activity-display-name-section{
  float:left;
  h1{
    .fontSize(@font-size + 4);
  }
}
.activity-notes{
  width: 100%;
}
