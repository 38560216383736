.ant-modal {

  //width: calculateRem(460px) !important;

  .ant-modal-header {
    border-bottom: 0;
    text-align: center;
    padding-top: 25px;
  }

  .ant-modal-body {
    text-align: center;
  }

  .ant-modal-footer {
    border-top: 0;
    text-align: center;
    padding: 25px 16px;
  }
}
