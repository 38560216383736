
.admin-container {
  .ant-tabs-nav {
    margin: 0;
  }
}
.admin-grid-configuration-container{

  .heading{
    font-size: 12px;
    padding-top:8px;
    margin-right: 5px;
  }




  .custom-field-type-display{
    padding-top: 18px;
    text-align: center;
    border-radius: 5px;
    height: 80px;
    background-color: white;
    border: 1px solid @primary-color
  }
  .custom-field-type-display:hover{
    cursor: pointer;
    background: @blue-rgba-10;
  }


}

.user-create-container{
  margin: 15px;
  .submit-admin-btn{
    margin-top: 15px;
  }
}

.menu-setting-container{
  margin-top: 25px;
}
