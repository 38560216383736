/* ---------------------------

	LOGIN

--------------------------- */

#login-screen, #reset-password-screen, #create-password-screen {
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  /*background: url('../../img/login.jpg') no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/

  #create-password-heading, #reset-password-heading{
    text-align: center;
  }
  #email-sent-message{
    margin-top: 60px;
  }
  .back-to-login{
    margin: 50px auto;
    text-align: center;
    font-size: 17px;

  }
  ul{
    padding: 0;
  }

  .login-container{
    z-index: 10;
    width: 400px;
    height: 100%;
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;

    .advance-logo{
      @media screen and (max-width: 992px) {
        margin-top: 240px;
      }
      @media screen and (min-width: 992px) {
        margin-top: 80px;
      }
      @media screen and (min-width: 1500px) {
        margin-top: 245px;
      }
      height: 40px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .button-submit, .forgot-password{
      text-align: center;
    }

    .login-card{
      padding: 60px;
      width: 365px;
      border-radius: 5px;
      margin: auto;
      background-color: @white;
      height: 370px;
      margin-top: 130px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20);
      .login-field{
        margin-bottom: 30px;
      }
    }
    .create-password-login-card{
      padding-top: 40px;    }

    .reset-password-login-card{
      padding-top: 90px;
    }

  }

  .icon.background{
    background: url('../../img/login-crop.png') no-repeat top;
    padding: 0px;
    margin: 0px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 60%;
    position: relative;
    z-index: 1;
  }

  .footer{
    height: 40%;
    position: relative;
    z-index: 1;
    .footer-container{
      position: fixed;
      bottom: 0;
      width: 100%;
      .customer-logo{
        height: auto;
        max-width: 200px;
        padding-bottom: 10px;
        padding-left: 10px;
      }
      .apurebase-logo{
        height: 50px;
        padding-right: 10px;
        padding-bottom: 10px;
        position: fixed;
        right: 0;
        bottom: 0;
      }
    }

  }

  .login-section {
    float: left;
    width: 100%;
    min-height: 40vh;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 20;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    padding-bottom: 0px;
    .app-logo {
      float: left;
      width: 100%;
      text-align: center;
      padding: 0 30px 75px 30px;
      margin-top: -340px;
      img { height: 32px; width: auto; }
      .system-name{
        color:white;
        font-size: 36px;
      }

      img{
        margin-bottom: 150px;
      }
      .system-name-container {
        text-align: center;
        height: 400px;
        margin: auto;
        max-width: 500px;
        background-color: #FFFFFF;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.20);
        border-radius: 15px;

        .system-name-container-input {
          width: 75%;
          padding-top: 50px;
          margin: auto;
        }

        form#login-form, form#reset-password-form, form#create-password-form {
          .login-field {
            float: left;
            width: 100%;
            position: relative;

            &.forgot-password{
              margin-top: 25px;

              p{
                color: @primary-color;
              }

              a {
                color: @primary-color;

              }
              a:visited{
                color: @primary-color;
              }
              a:hover{
                text-decoration: underline;
              }
            }



            input[type="text"], input[type="password"] {
              float: left;
              width: 100%;
              height: 54px !important;
              background: #fff;
              border-width: 2px;
              border-radius: 100px;;
              .fontSize(15px) ;
              padding: 0 54px;
              text-align: left;
              position: relative;
              margin-bottom: 35px;

              &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                text-align: center;

              }

              &::-moz-placeholder { /* Firefox 19+ */
                text-align: center;
              }

              &:-ms-input-placeholder { /* IE 10+ */
                text-align: center;
              }

              &:-moz-placeholder { /* Firefox 18- */
                text-align: center;
              }

              &:focus {
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  color: #fff;
                }

                &::-moz-placeholder { /* Firefox 19+ */
                  color: #fff;
                }

                &:-ms-input-placeholder { /* IE 10+ */
                  color: #fff;
                }

                &:-moz-placeholder { /* Firefox 18- */
                  color: #fff;
                }
              }
            }

            &.login-username {
              .icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 54px;
                height: 54px;
                background: url('../../img/icon-login-user.svg') no-repeat center;
              }
            }

            &.login-password {
              .icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 54px;
                height: 54px;
                background: url('../../img/icon-login-password.svg') no-repeat center;
              }
            }

            &.login-domain {
              .icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 54px;
                height: 54px;
                background: url('../../img/icon-login-domain.svg') no-repeat center;
              }
            }
          }

          .login-button {
            height: 54px;
            .fontSize(15px);
            margin-top: 25px;

            > span {
              margin-left: 0;
            }
          }

          .lost-password {
            float: left;
            width: 100%;
            .fontSize(15px);
            color: @light-grey;
            margin-top: 20px;
            text-align: center;
          }
        }
      }

      @media screen and (min-width: 601px) {
        .system-name-container {
          min-width: 500px;
        }
      }

      /* On screens that are 600px or less, set the background color to olive */
      @media screen and (max-width: 600px) {
        .system-name-container {
          width: 350px;

        }
      }

      @media screen and (max-width: 375px) {
        .system-name-container {
          width: 300px;

        }
      }

    }

  }
}

