/* ---------------------------

	Activity Card

--------------------------- */

.activity-card {
  float: left;
  width: 100%;
  background: #fff;
  padding: 20px;
  margin-bottom: 10px;
  color: @grey;
  .animate();
  position: relative;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .activity-top {
    float: left;
    width: 100%;
    margin-bottom: 4px;

    h3 {
      float: left;
      color: @green;
      .fontSize(15px);
      .lineHeight(19px);
      font-weight: 700;
    }

    .activity-time {
      float: right;
      .fontSize(@font-size - 2);
      .lineHeight(16px);
      color: @grey;

      .date {
        display: flex;
      }

      .time {
        margin-left: 19px;
      }

      &:before {
        content: "";
        float: left;
        width: 14px;
        height: 14px;
        background: url('../../img/icon-clock.svg') no-repeat center;
        margin-top: 1px;
        margin-right: 5px;
      }
    }
  }

  .doctor-info {
    float: left;
    width: 100%;
    margin-bottom: 20px;

    .doctor-name {
      float: left;
      width: 100%;
      font-weight: 700;
      color: @dark;
      margin-bottom: 0;
    }

    .doctor-title {
      float: left;
      width: 100%;
      font-weight: 400;
      .fontSize(@font-size - 3);
      .lineHeight(14px);
      margin-bottom: 0;
      margin-top: 4px;
    }

    .location-short-address {
      float: left;
      width: 100%;
      .fontSize(@font-size - 3);
      .lineHeight(14px);
      margin-top: 10px;
      margin-bottom: 0;
      padding-right: 20%;
    }
  }

  .activity-badges {
    float: right;

    .activity-badge {
      float: left;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid @light-grey;
      margin-left: 5px;

      &.delivery-badge {
        background: url('../../img/icon-pill.svg') no-repeat center;
      }

      &.notes-badge {
        background: url('../../img/icon-notes.svg') no-repeat center;
      }

      &.person-badge {
        border: none;
        position: relative;

        .person-image {
          float: left;
          width: 30px;
          height: 30px;
          overflow: hidden;

          img {
            width: 100%;
            height: auto;
          }
        }

        .badge-alert {
          position: absolute;
          top: -6px;
          right: -6px;
          width: 22px;
          height: 22px;
          border: 2px solid #fff;
          background-image: url('../../img/icon-excl-white.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-color: @grey;
          border-radius: 50%;

          &.alert-red {
            background-color: @red;
          }

          &.alert-orange {
            background-color: @action-color;
          }
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
  }
}
