.live-match-search-container {

  .item {
    width: 100%;

    .item-left {
      float: left;
      max-width: calc(~"100% - 24px");
      white-space: nowrap;
      overflow: hidden;
      box-sizing: border-box;
      text-overflow: ellipsis;

      &.global { color: @text-color; }
      &.local {
        color: @dark-green;
        cursor: pointer;
      }
    }
    .item-right {
      float: right;
    }
  }
}
