.entities-search-top-container {

  width: 100%;

  // Override ant so we can have groups without any margin between.
  .ant-form-inline {
    .ant-form-item { margin-right: 0; }
    .ant-form-item:last-child { margin-right: @padding-md; }
  }

  .criteria-plus {
    height: 60px;
    display: flex;
    align-self: flex-end;
    padding-top: 18px;
    .criteria-plus-icon {
      color: @primary-color;
    }
  }

  .global-switch {
    .ant-switch-handle, .ant-switch-handle::before {
      background-image: url("../../../../img/global-search-globe.svg");
    }
  }

}
