
.details-page-section-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: @padding-xss;

  .left {
    color: @light-grey;
    font-weight: 500;
    font-size: 12px;
  }
  .right {
    align-self: flex-end;
    padding-bottom: @padding-xss;
    display: flex;
  }
}
