.badge-container {

  display: flex;
  justify-content: space-around;
  column-gap: 4px;

  &.primary .ant-badge-count {
  background-color: @primary-color;
}
  &.orange .ant-badge-count {
    background-color: @action-color;
  }
  &.blue .ant-badge-count {
    background-color: @blue;
  }
  &.large .ant-badge-count{
    height: 30px;
    min-width: 30px;
    padding-top: 5px;
    font-weight: 600;
    margin-left: 15px;
    border-radius: 30px;
    .fontSize(@font-size + 3);
  }

  &.medium .ant-badge-count{
    height: 25px;
    min-width: 25px;
    padding-top: 2px;
    font-weight: 600;
    margin-left: 15px;
    border-radius: 30px;
    .fontSize(@font-size + 2);
  }

  &.small .ant-badge-count{
    height: 20px;
    min-width: 20px;
    margin-left: 10px;
    border-radius: 30px;
    .fontSize(@font-size - 1);

    .ant-scroll-number-only-unit{
      color: @white;
      .fontSize(@font-size - 3)
    }
  }


  &.badge-icon {
    display: inline;
    border-radius: 50%;
    padding: 4px;
    margin-left: 3px;
    font-weight: 700;
    .fontSize(@font-size - 2);
  }
}

.icon-badge-container {
  .icon {
    color: @white;
    .fontSize(@font-size - 1);

    &.primary {
      background-color: @primary-color;
    }
    border-radius: 50px;

    &.small {
      width: 25px;
      height: 25px;
      text-align: center;
    }

    &.medium {
      width: 55px;
      height: 55px;
    }

    &.large {
      width: 65px;
      height: 65px;
    }
  }
}


.ant-carousel .slick-slide .card-body {
  img {
    display: inherit;
  }
  .badge-container{
    //margin-top: 11px!important;
  }

}

.primary-badge {
  margin-left: 5px;
  .ant-badge-count {
    background-color: @green-rgba-100;
  }
}
