
.apb-results-header-for-table-container {
  font-size: 18px;
  display: flex;
  justify-content: space-between;

  .count {
    color: @heading-color;
  }

  .selected-count {
    padding-left: @padding-xss;
    color: @text-color-secondary;
  }
}
