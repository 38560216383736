

.entities-simple-search-container {

  .ant-collapse-content-active {
    .clemScrollBar(calc(~"100vh - 255px"), 8px);
  }

  .entities-search-drawer-simple-form {

    > div {
      display: grid;
      grid-template-columns: auto auto;

      > div {
        margin: @padding-xs @padding-xs @padding-xs 0;
      }

    }

  }
}

