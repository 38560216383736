.manual-person-matching-container {

  &.grayscale {
    opacity: 0.25;
  }

  .Difference {
    > del {
      background-color: rgb(255, 224, 224);
      text-decoration: none;
    }
    > ins {
      background-color: rgb(201, 238, 211);
      text-decoration: none;
    }
  }

  .testing-stuff > td:first-child {
    border-left: 4px solid @blue;
  }

}
