.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center-inline {
  display: flex;
  align-items: center;
  & * > {
    margin-right: 5px;
  }
}