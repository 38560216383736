
.dashboard-records-imported-item-container {

  .radio-for-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: @padding-sm;
  }
}
