
.entity-table-container {
  width: 100%;

  @inactive-border-color: @text-color-secondary;

  .above-table {
    display: flex;
    justify-content: space-between;
    column-gap: @padding-sm;
    align-items: center;
    margin-bottom: 1em;

    .left {
      flex-shrink: 0;
    }

    .right {
      display: flex;
      text-align: end;
      column-gap: 8px;
      align-items: center;
    }
  }

  table {
    border-collapse: collapse;
    td > div.last-visited {
      border-right: 3px solid @blue;
    }

    tr.orange {
      border-left: @padding-xss solid @action-color;
    }

    .row-double-clickable {
      // cursor: pointer;
    }

    .row-line {
      &.global { td:first-child { box-shadow: inset 0 0 0 4px @secondary-color; } }
      &.inactive { td { background: @inactive-background-color; } }
      &.apbOptOut { td { background: @opt-out-background-color;} }
      td:first-child {
        &:before {
          content: "";
          margin-left: -5px;
          width: 100%;
          height: 100%;
          background: white;
          position: absolute;
          top: 0;
        }
      }
    }
  }

  table.large-row {
    tr {
      padding: 10px 0;
    }
  }
  table.small-row {
    tr {
      padding:0;
    }
  }

  .below-table-left {
    display: flex;
    column-gap: 36px;

    .description {
      font-size: @font-size-lg;
      padding-left: 4px;

      &.inactive {
        border-left: 3px solid @inactive-background-color;
      }
      &.apbOptOut {
        border-left: 3px solid @opt-out-background-color;
      }
      &.global { border-left: 3px solid @secondary-color; }
    }
  }
}
