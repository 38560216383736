
.dashboard-grid-view-container {

  .layout {
  }

}

.dashboard-view-container .main-view-bounding-background{
  padding: 25px 25px 25px 25px !important;

  .dashboard-grid-top-actions-container{
    margin-right: 20px;
  }
}