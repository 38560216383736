
.criteria-multi-select-input-container {
  width: 100%;
}

.criteria-multi-select-input-container-multi-group {
  .ant-tag {
    display: block;
    overflow: hidden;
    white-space: normal;
    margin-bottom: 1px;
  }

  .ant-tag-text {
    display: inline-block;
    margin-right: -4px; // suppress extra margin due to the X button
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top; // overflow: hidden moves the element up a few pixels, this fixes the issue
    max-width: 90px;
  }

  .ant-select-selection-item {
    align-items: center;
    border-color: rgb(217, 217, 217);
    height: 22px !important;
  }

  &.error.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 6px @error-color;
  }
}

.criteria-multi-select-input-container-dropdown-container {
  .rc-virtual-list-scrollbar-thumb {
    background: @primary-color !important;
  }

  .ant-select-item-option {
    .option-badge { background-color: lightgray; }
    &.ant-select-item-option-selected { .option-badge { background-color: @primary-color; } }
    .option-value {
      display: flex;
      justify-content: space-between;

      // Copied from ant css example. Using custom badge as ant badge is too slow.
      .option-badge {
        padding: 0 6px;
        min-width: 20px;
        height: 20px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        border-radius: 10px;
        color: @white;
      }
    }
  }
}
