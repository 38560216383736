
.slider-action-container-tmp {

}

.slider-action-container {
  .content {
    width: 320px;
    display: flex;
    flex-wrap: wrap;

    .left {
      flex-grow: 1;
      flex-basis: 140px;
    }
    .right {
      flex-grow: 1;
      text-align: right;
      margin: auto 0;
    }

    .ant-slider {
      .ant-slider-rail {
        background-color: @border-color-base;
      }
      .ant-slider-track {
        background-color: @secondary-color;
      }
      .ant-slider-handle {
        border-color: @secondary-color;

        &:focus {
          box-shadow: #7F7F7F;
        }
      }
    }
  }
}
