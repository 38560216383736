.clear{
  clear: both;
}


/* ---------------------------

	TOP

--------------------------- */
// TODO: Move this css container over to a new file only related to the Header.tsx
.header-container {
  display: flex;
  height: 64px;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.10);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  border-bottom: 6px solid @primary-color;
  justify-content: space-between;
  align-items: center;

  .app-logo {
    margin-left: 30px;
    width: 220px;
    text-align: center;
    line-height: 60px;
  a {
    display: flex;
    img {
      display: inline-block;
      width: auto;
      max-height: 32px;
    }
  }

  }

  .user-menu {
    margin-right: 30px;
    display: flex;
    align-items: center;
    column-gap: @padding-md;
    cursor: pointer;

    .chevron {
      padding-bottom: 4px;
    }
    .user-info {
      display: flex;
      flex-direction: column;
      .name {
        color: @grey;
        .fontSize(@font-size - 1);
      }
      .role {
        color: @light-grey;
        .fontSize(11px);
        .lineHeight(11px);
      }
    }
  }
}


.ant-drawer-close .anticon.anticon-close{
  color: white;
  font-weight: 600;
}

.new-aciton-comp {
  position: fixed;
  top: 0;
  right: 0;
  margin-top: -12px;
  margin-right: -12px;
  z-index: 210;

  .blob{
    margin-right: 44px;
    margin-top: 20px;
  }

  .new-action {
    float: left;
    cursor: pointer;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    background: fade(@action-color, (.3*100));
    .animate();
    position: relative;

    .new-action-inner {
      float: left;
      width: 44px;
      height: 44px;
      margin: 20px;
      border-radius: 50%;
      background: @action-color url('../../img/icon-plus-white.svg') no-repeat center;
    }

    &:hover {
      background: fade(@action-color, (.4*100));
    }


  }

  .dropdown-list {
    margin-top: 40px;
    margin-right: 15px;
  }

  @media (max-width: 767px) {
    bottom: 0;
    top: auto;
    margin-top: auto;
    margin-bottom: -12px;
  }
}

.loading-container{
  text-align: center;
}
