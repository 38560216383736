
.report-view-container {
  .steps-content {
    .report-card {
      cursor: pointer;
      text-align: center;

      &.selected {
        background-color: fade(@primary-color, (0.15*100));
      }
    }
  }
}
