
.menu-settings-container {

  margin-top: @padding-sm;
  width: clamp(320px, 100%, 640px);

  .menu-item {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      .icon-container {
        span.apb-icon {
          color: @grey;
        }
      }
    }

    .right {
      display: flex;
      column-gap: @padding-md;
      align-items: center;
      padding-right: @padding-md;
    }
  }

}
