@import "_calendarCard";

.fc-button-primary{
  background-color: white !important;
  color:@dark !important;
  border-color: @border-color!important;
  font-family: @font !important;
  font-size: 14px!important;
  text-transform: capitalize!important;
  padding-left: 15px!important;
  padding-right: 15px!important;

  &.fc-button-active, &.fc-next-button-active{
    color:@green !important;
    border-color: @green !important;
  }
  &.fc-button-active:focus, &.fc-prev-button:focus, &.fc-next-button:focus, &.fc-today-button:focus{
    box-shadow: 0 0 0 0.2rem @green-rgba-50 !important;
  }
  &.fc-button-active:active{
    box-shadow: 0 0 0 0.2rem @green-rgba-50 !important;
  }
  &.fc-button-active:target{
    box-shadow: 0 0 0 0.2rem @green-rgba-50 !important;
  }
}

.fc-scrollgrid{
.fc-timegrid-slots{
  tr{
    height: 50px;
  }
}
}
