
.user-admin-card-container {

  padding: 0 64px 42px 0;

  .title {
    border-bottom: 2px solid @border-color;
    width: 100%;
    font-size: @heading-4-size;
  }

  .card-content {
    .icon-area {
      padding: @padding-sm;
    }
    .content {
      padding: @padding-sm 0;
    }
  }
}
