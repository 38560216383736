.search-condition-group-container {
  display: flex;
  flex-direction: column;
  .next-operation-container {
    position: relative;
    margin: 15px 0;
    text-align: center;
  }
  .search-condition-criteria-list {
    padding: 0 0 0 40px;
    counter-reset: ol-counter;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 1em;
    width: 100%;
    .search-condition-element-container {
      position: relative;
      counter-increment: ol-counter;
      margin-bottom: 20px;
      &:before {
        position: absolute;
        z-index: 1;
        left: -40px;
        top: 10px;
        width: 25px;
        height: 25px;
        text-align: center;
        border: 1px solid @border-color-base;
        border-radius: 50%;
        content: counter(ol-counter);
      }
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        left: -28px;
        top: 40px;
        width: 1px;
        border-left: 1px solid @border-color-base;
        height: calc(100% - 15px);
      }
    }
  }
}
.search-condition-group-container.nest-group {
  width: calc(100% + 40px);
}
/*
.nested-group {

  padding-left: 20px;
  &:before {
    content: "";

  }
}*/
