@import "components/_siteLocation";
@import "components/_siteAffiliationContainer";
@import "components/SiteCherryPickTableButton/_siteCherryPickTableButton";

.site-antd-table-column-width {
  &.mw-350 { min-width: 350px }
  &.mw-275 { min-width: 275px }
}

.site-details-container {
  .site-header-section {
    margin-left: @padding-left-detail-views;
  }
}

// TODO: Delete all of the below
.site-detail-view-content-container {
  .content-top {
    margin-bottom: 0;

  }

  .position-card-col .ant-col-4.icon{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 5px;
  }

  .site-header-section{
    margin-left: @padding-left-detail-views;
    span {
      display: flex;

      .gdpr-ready {
        height: 30px;
        margin-left: 10px;
      }

      h6 {
        margin-top: 8px;
      }
    }
  }
  .site-tab-section{
    margin-top: 9px;

  }

  .site-detail-cards-container{
    margin-left: 25px;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .site-detail {
    margin-bottom: 45px;
    .site-dispay-fields {
      float: left;
      width: 200px;
      margin: 50px 50px 0 0;
      .field-label {
        font-weight: bold;
        .fontSize(14px);
        color: black;
      }
    }
  }
  .content-right-section{
    .entity-top-right-section  {
      float: right;
      margin-top: 1px;
      margin-left: @small;
      .edit-button-message{
        margin-right: @x-small;
      }
    }
    .icon-list-type{
      float: right;
      margin-right: 20px;

    }
  }
  .custom-field-container {
    border-bottom: solid 2px #e9e9e9;
    margin-bottom: @large;
    .ant-row {

    }
  }

  .site-filter-codes{
    width: 50%;
    margin: 40px 0;
    h2{
      float: left;
      padding-right: 5px;
    }
  }
  .content-top{
    margin-bottom: 0 !important;
  }
  .content-section-person-container {
    border-top: solid 2px @border-color;
    position: relative;

    .content-section-header {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 1px;
      height: 25px;
      border: solid 2px @border-color;
      border-radius: 10px;
      text-align: center;
      position: absolute;
      background-color: @background-color-grey;
      top: -15px;
      left: 0;
      right: 0;
      margin: auto;
      width: 150px;
    }
  }

  .clear {
    clear: both;
  }

  .site-location{
    width: 50%;
  }

  .hierarchy-site-view {
    margin-left: 20px;

    .ant-tree-treenode-selected {
      .ant-tree-switcher-noop i {
        color: @primary-color
      }
      .ant-tree-node-selected a {
        color: @white;
      }
    }

  }
  .ant-tabs-tabpane{
    word-break: break-word;
    .consent-person-header h2{
      float: left;
      margin-right: 5px;
    }
  }


  .ant-tabs > .ant-tabs-nav{
    margin-left: @padding-left-detail-views;
  }

}

