
.form-select-container {
  .ant-select {
    width: 100%;
  }
}

.select-dropdown-container-scrollbar {
  .ant-select-dropdown-menu-item:hover, .ant-select-dropdown-menu-item-active {
    background-color: fade(@primary-color, (0.15*100));
  }
}
