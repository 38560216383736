@import "./fieldTypes/PersonSegmentationTabField/_productSegmentationCodeCard";

.entity-tab-fields-card-container {

  > div:not(:first-child) {
    padding-top: 25px;
  }

  .custom-field-heading{
    font-weight:600;
    padding-right: 10px;
  }

  .custom-field-consent-value{
    margin-left:9px;
  }
}

