.entity-cascader-container {
  position: relative;
  width: 100%;

}
.entity-cascader-footer {
  padding: 5px;
}

.entity-cascader-menu-container {
  .ant-cascader-menu {
    max-width: 150px;
    overflow-x: hidden;
    .ant-cascader-menu-item-content {
      width: calc(100% - 10px);
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: none;
    }
  }
}