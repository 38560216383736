
.admin-grid-right-relations-configurator-container {
  width: 100%;

  .grid-item {
    display: flex;
    justify-content: space-between;

    .left { cursor: text; }
    .right { cursor: pointer; }
  }

}
