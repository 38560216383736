
.use-table-columns-title-container {
  display: flex;
  justify-content: space-between;

  .column-sorting {
    color: @action-color;
  }

}
