
.light-collapse-container {

  .ant-collapse-arrow {
    padding-left: 12px;
  }

  .ant-collapse-header {
    padding-left: 52px !important;
  }

  .ant-collapse-content {
    padding-left: 12px;
  }
}
