
.entity-header-container {
  display: flex;
  align-items: center;

  .title {
    margin-right: 10px;
    font-size: 22px;
    font-weight: 600;
  }
}

