.select-dropdown-container-scrollbar {
  .ant-select-dropdown-menu-vertical {
    .scrollBar(200px);
  }
}

.dropdown-overlay-container {
  width: 240px;

  &.ant-popover-placement-bottomRight .ant-popover-arrow {
    right: 10px;
  }

  .ant-popover-title {
    padding: 0;

    .dropdown-search > input{
      border: none;
      border-radius: 4px 4px 0 0;
    }
  }


  .ant-popover-inner {
    margin: 0;
    padding: 0;

    .ant-popover-inner-content {
      .scrollBar(200px);
      padding: 0;

      .ant-list-item {
        padding-left: 12px;
        border: none;

        &:hover, &.item-selected {
          background-color: alpha(@primary-color, 0.15);
          cursor: pointer;

          .anticon-check {
            margin-left: auto;
            padding-right: 12px;
            padding-top: 2px;
            float: right;
          }
        }
      }
    }
  }
}
