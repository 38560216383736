
.entity-tab-card-container {
  height: 100%;
  width: 100%;
  border-color: @gray-rgba-30;
  margin-right: 10px;



  &.ant-card{
    margin-left: 0!important;
    margin-right: 10px;
    .clemScrollBar(100%, 8px);

    .ant-card-head {
      background-color: @body-background;
      color: @white;
      font-weight: 600;
    }

    // To get rid of the scrollbar when not needed. Ant defaults padding to 24px
    .ant-card-body { padding-bottom: 0; }
  }
}
