
.contact-card-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  //float: left; // float-left is causes a bunch of unexpected designs
  width: 225px;
  height: auto;
  padding: 15px;

  &.small {
    width: 175px;
    height: 120px;
    padding: 5px;
    margin-bottom: 20px;

    .add-card {

      height: 130px;
    }

  }
  &.xsmall {
    width: 150px;
    padding: 4px 15px;
  }

  h3 {
    font-size: @font-size + 2 !important;
  }


  &.large {
    width: 350px;
    padding: 15px 15px 0 0;

    .content {
      padding: 0;
      min-height: 340px;

      h3 {
        padding: 0 10px;
      }
    }

    .icon-rounded {
      margin-top: 16px;
    }

    .content-container {
      margin-bottom: 16px;
    }

    .basefields-container {
      min-height: 150px;
      text-align: left;

    }

    .collpase-children-content {
      text-align: left;
      margin-bottom: 20px;
    }

    .closed, .re-collapse-item {
      width: 267px;
      text-align: center;
    }

  }

  .content-container {
    width: 80%;
    border-top: #F5F5F5 2px solid;
    margin: 10px auto;
    padding-top: 10px;
    text-align: left;
  }

  &.loading {
    animation: loading-blinker 1s ease-in-out infinite alternate;
  }

  @keyframes loading-blinker {
    from {
      opacity: 0.7
    }
    to {
      opacity: 0.25
    }
  }

  .add-card {
    background: none;
    cursor: pointer;
    border: 3px dashed #CACACA;
    border-radius: 5px;
    padding: 20px 0;
    width: 100%;
  }

  .content {
    float: left;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
    padding: 10px;
    text-align: center;

    display: flex;
    position: relative;
    flex-flow: column;
    flex-wrap: wrap;

    .card-badge {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid #F5F5F5;
      background-color: @light-grey;

      // MARK: Currently not being used
      &.pending {
        background-color: @light-grey
      }

      &.not-signed {
        background-color: @action-color
      }

      &.signed {
        background-color: @green
      }

      img {
        margin-top: 7.5px;
        width: 18px;
        height: 18px;
        display: initial;
      }
    }

    .icon {
      display: inline-block;
      text-align: center;
      position: relative;
      align-self: center;
      margin-bottom: 15px;
      height: 60px;
      width: 60px;
      line-height: 60px;

      img {
        text-align: center;
      }

      &.icon-rounded {
        border-radius: 50%;
        background: #F5F5F5;


        img {
          display: initial;
        }
      }


      .icon-badge {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 30px;
        height: 30px;
        background: @blue;
        line-height: 30px;
        text-align: center;
        color: #fff;
        font-weight: 700;
        border-radius: 50%;
      }
    }

    h3 {
      float: left;
      width: 100%;
      .fontSize(14px);
      .lineHeight(18px);
      color: #000;
      font-weight: 700;
      margin-bottom: 0;
    }

    .sub-title {
      float: left;
      width: 100%;
      .fontSize(@font-size - 2);
      .lineHeight(16px);
      color: @grey;
    }
  }

  span.remove {
    margin: 5px;
    color: @secondary-color;
    cursor: pointer;
    font-size: @font-size;

    &:hover {
      text-decoration: underline;
    }
  }
}

.site-detail-view-content-container, .person-detail-view-content-container, .link-detail-view-content-container {
  .icon-badge {
    width: 30px;
    height: 30px;
    font-size: 16px;
    margin-left: 10px;
    background: @blue;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    border-radius: 50%;
  }
}

.attribute-card {
  width: 350px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
  padding-top: 15px;
  display: inline-table;
  margin-right: 20px;
  padding-bottom: 20px;


  .icon-container {
    width: 80%;
    min-height: 115px;
    margin: auto;
    margin-bottom: 10px;
    border-bottom: #F5F5F5 2px solid;
    position: relative;


    .title-container {
      text-align: center;
      font-size: 16px;
      padding-top: 5px;
      font-weight: 600;
    }

    .sub-title-container {
      text-align: center;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .icon-rounded {
      margin: auto;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background: #F5F5F5;
      display: flex;
      justify-content: center;
      align-items: center;


      .card-badge {
        top: -10px;
        right: -10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 3px solid #F5F5F5;
        background-color: @light-grey;
        position: absolute;
        // MARK: Currently not being used
        &.pending {
          background-color: @light-grey
        }

        &.not-signed {
          background-color: @action-color
        }

        &.signed {
          background-color: @green
        }

        img {
          margin-top: 7.5px;
          width: 18px;
          height: 18px;
        }
      }


      img {
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;


      }
    }

    .card-badge {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid #F5F5F5;
      background-color: @light-grey;

      // MARK: Currently not being used
      &.pending {
        background-color: @light-grey
      }

      &.not-signed {
        background-color: @action-color
      }

      &.signed {
        background-color: @green
      }

      img {
        margin-top: 7.5px;
        width: 18px;
        height: 18px;
      }
    }

  }

  .content-container {
    width: 80%;
    margin: auto;
    text-align: center;

  }
}


a .scrollmenu-items {
  color: #000;
}

a .icon span {
  color: #7F7F7F;
}


.calendar-criteria-button {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-btn-primary {

  }
}

.caleendar-search-placeholder {
  margin-top: 10px;
  text-align: center;
}

.cards-container-clear {
  display: flex;
  flex-direction: column;
  width: 100%;
  &:after {
    clear: both;
    display: table;
    content: "";
  }

}

.activity-overview-card-container {

  .card-body {
    width: 99%;
    min-height: 75px;
    background: #fff;
    border-radius: 5px;
    border-left-width: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
    padding: 8px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: border-left-width 100ms ease-in-out;

    &:hover {
      border-left-width: 8px;
    }


    /*    &.GREEN{
          background-color: @green-rgba-10;
        }
        &.BLUE{
          background-color: @blue-rgba-10;
        }
        &.GRAY{
          background-color: @gray-rgba-10;
        }*/
    .card-title {
      font-size: @font-size + 1;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .card-date {
      font-size: @font-size - 1;
      font-weight: 500;
    }

    .card-status {
      display: flex;
      font-size: @font-size - 1;

      .apb-icon {
        margin-right: 0px;
      }

      .type-icon {
        margin-top: 5px;
      }
    }
  }
}
