
.blobs-container {


  &.left {
    display: flex;
    align-items: center;
  }

  .label {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
    .fontSize(@font-size - 1);
  }

  .label-left {
    padding: @padding-md;
    &.x-small { font-size: @font-size-base; }
    &.small { font-size: ceil(@font-size-base * 1.2); }
    &.medium { font-size: ceil(@font-size-base * 1.4); }
  }

  .blob {
    background: @dark;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: auto;
    cursor: pointer;
    position: relative;
    transform: scale(1);
    animation: pulse-black 2s infinite;

    display: flex;
    align-items: center;
    justify-content: center;

    &.action {
      background: @orange-rgba-100;
      box-shadow: 0 0 0 0 @orange-rgba-100;
      animation: pulse-orange 2s infinite;
    }

    //&.remove {
    //  background: @cancel-red;
    //  box-shadow: 0 0 0 0 @cancel-red;
    //  animation: pulse-red 2s infinite;
    //}

    &.primary {
      background: @green-rgba-100;
      box-shadow: 0 0 0 0 @green-rgba-100;
      animation: pulse-green 2s infinite;
    }

    .collapse { background-color: red; }
    .anticon svg {
      color: @white;
    }

    &.small {
      width: 32px;
      height: 32px;
      .default-plus-icon { font-size: 22px; }
    }
    &.x-small{
      width: 24px;
      height: 24px;
      .default-plus-icon { font-size: 14px; }
    }
    &.medium {
      width: 44px;
      height: 44px;
      .default-plus-icon { font-size: 30px; }
    }

  }
}

