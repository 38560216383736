.name-tag {
  display: flex;
  float: left;
  height: auto;
  width: auto;
  .content {
    float: left;
    width: 60px;
    text-align: center;
    display: flex;
    position: relative;
    flex-flow: column;
    flex-wrap: wrap;

    .pill-icon {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid #F5F5F5;
      background-color: @light-grey;
      background-image: url('../../../img/icon-pill-white.svg');
      background-repeat: no-repeat;
      background-position: center;

      &.pending {
        background-color: @light-grey;
      }

      &.not-signed {
        background-color: @action-color;
      }

      &.signed {
        background-color: @green;
      }
    }

    .icon {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #fff;
      line-height: 60px;
      text-align: center;
      position: relative;
      align-self: center;
      margin-bottom: 15px;

      .person-segment-badge {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        font-weight: 700;
        border-radius: 50%;

        &.a {
          background: @primary-color;
        }

        &.b {
          background: @light-green;
        }

        &.c {
          background: @blue;
        }

        &.d {
          background: lightblue;
        }
      }
    }

    h3 {
      float: left;
      width: 100%;
      .fontSize(14px);
      .lineHeight(18px);
      color: #000;
      font-weight: 700;
      margin-bottom: 0;
    }

    .person-title {
      float: left;
      width: 100%;
      .fontSize(13px);
      .lineHeight(16px);
      color: @grey;
    }
  }

  .name-container {
    min-width: 800px;
    padding-left: 20px;
    padding-top: 5px;

    &.single {
      display:flex;
      align-items: center;
      margin-bottom: 8px;
    }
    &.multiple {
      h1 {
        margin-bottom: 0;
      }
    }

    a {
      color: @blue;
    }
  }
  .site-affiliations {
    clear: both;
    font-size: @font-size;
  }
}
