.affiliation-card-container {
  margin-left: -@padding-left-detail-views;

  .note-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

  }
  .link{
    width: 5%;
    text-align: right;
    .fontSize(@font-size+2);
    color: @primary-color;
    cursor: pointer;
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }
  .note-icon-container{
    width: 50px;
    text-align: center;
    color: @grey;
    position: relative;

    .note-icon{
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0%;
      padding-left: 4px;
    }

  }
  .note-text{
    font-weight: 500;
    border-left: 1px solid @border-color;
    padding-left: 20px;
    .fontSize(@font-size - 3);
    width: 95%;
    .updated-date{
      margin-left: 20px;
    }
    .desc{


      .title{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
      }
      .text{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: @sub-category-text;
      }


    }

  }


  .affiliation-container {
    max-width: 1215px;

    .left {
      float: left;
      width: 25px;
      padding-top: 27px;
      height: 100%;
      background-color: transparent;

      .status {
        .fontSize(@font-size + 3);

        &.icon-done {
          color: @primary-color;
        }

        &.icon-open {
          color: @action-color;
        }

        &.icon-progress {
          color: @green;
        }

        &.icon-cancel {
          color: @grey;
        }

        &.icon-inactive {
          color: @red;
        }
      }

    }

    .right {
      @media screen and (min-width: 1435px) {
        width: 98%;

      }
      @media screen and (max-width: 1435px) {
        width: 97%;
      }
      @media screen and (max-width: 1000px) {
        width: 96%;
      }
      @media screen and (max-width: 800px) {
        width: 95%;
      }
      @media screen and (max-width: 666px) {
        width: 94%;
      }

      height: 100%;
      margin-left: 25px;

      background-color: @white;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20);
      border-radius: 5px;
      margin-top: 15px;
      margin-bottom: 5px;
      padding: 6px 20px 10px 15px;

      .card-information {
        width: 362px;
        border-right: 1px solid @border-color;
        float: left;
        height: 60px;


        .card-title {
          font-weight: 600;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          .fontSize(@font-size - 1);

          &.small-font {
            .fontSize(@font-size - 2);
          }
        }

        .card-date {
          font-weight: 600;
          .fontSize(@font-size - 3)
        }

        .card-type {
          color: @sub-category-text;
          .fontSize(@font-size - 3)
        }

      }

      .card-context {
        float: left;
        display: contents;

        .card-context-header {
          height: 20px;
          color: @sub-category-text;
          .fontSize(@font-size - 3);
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          .date-created {
            padding-left: 10px;
          }

          .created-by {
            margin-left: 5px;
          }

          .link {
            float: right;
            .fontSize(@font-size+2);
            color: @grey;
            cursor: pointer;
          }
        }

        .card-content {
          display: contents;

          color: @sub-category-text;
          .fontSize(@font-size - 3);

          .note-heading {
            display: flex;
            padding-left: 10px;
          }

          .note-content {
            display: flex;
            padding-left: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            .note-content-empty {
              font-style: italic;
            }
          }

        }
      }


      &.no-color {

        .link {
          float: right;
          width: 25px;
          text-align: center;
          font-size: 16px;
          color: @action-color;
          cursor: pointer;
        }
      }

      &.color {
        &.grey {
          border-left: solid @grey;
        }

        &.green {
          border-left: solid @green;
        }

        &.light-green {
          border-left: solid @light-green;
        }

        &.dark-green {
          border-left: solid @dark-green;
        }

        &.blue {
          border-left: solid @blue;
        }

        &.red {
          border-left: solid @red;
        }

        &.orange {
          border-left: solid @action-color;
        }

        &.yellow {
          border-left: solid yellow;
        }
      }
    }

  }
}
