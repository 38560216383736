

.single-line-skeleton-container {

  &.centered {
    margin: auto;
  }

  &.width-125 { width: 125px; }
  &.width-160 { width: 160px; }
  &.width-240 { width: 240px; }
  &.width-auto {
    width: 100%;
    height: 19px;
    .ant-skeleton-content > .ant-skeleton-title {
      margin-top: 0;
    }

  }
}
