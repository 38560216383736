
.calendar-top-menu-container {

  .test {
    border-radius: 100px;
  }

  .actions {
    display: flex;
    column-gap: @padding-lg;

    .display-mode-group {
      label:first-child {
        border-bottom-left-radius: 100px;
        border-top-left-radius: 100px;
      }
      label:last-child {
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
      }
    }
    .date-navigation {
      display: flex;
      column-gap: @padding-xs;
      .arrow {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        vertical-align: middle;
        line-height: 32px;
        color: @primary-color;
      }
    }
  }

  .selected-date-display {
    margin: @padding-md 0;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      .primary {
        font-size: 24px;
        font-weight: bold;
        padding-right: @padding-xss;

      }
      .secondary {
        font-size: 24px;
        font-weight: 300;
        opacity: 0.75;
      }
      .date-navigation{
        margin-left: 5px;
      }
    }

  }

}
