/* ---------------------------

    FONT

--------------------------- */

@import (css) url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');


.font-300 { font-weight: 300 !important; }
.font-400 { font-weight: 400 !important; }
.font-700 { font-weight: 700 !important; }




/* ---------------------------

	CALC

--------------------------- */

.calculateRem(@size) {
  @result: unit(@size / @font-size, rem);
}



.fontSize(@size) {
  //font-size: @size; //Fallback in px
  font-size: .calculateRem(@size)[@result];
}

.lineHeight(@size) {
  //line-height: @size; //Fallback in px
  line-height: .calculateRem(@size)[@result];
}

// https://stackoverflow.com/a/18294634/1832471
.grabbable() {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}
