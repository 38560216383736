@import "_nameTag";
@import "_card";
@import "_advanceCard";
@import "_entityHeaderContainer";

/* ---------------------------

	CONTACT PERSON CARD

--------------------------- */
.contact-person-card, .add-contact-person {
  display: flex;
  align-items: center;
  flex-direction: column;
  float: left;
  width: 250px;
  height: auto;
  padding: 15px;

  .content {
    float: left;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
    padding: 20px;
    text-align: center;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    position: relative;

    .pill-icon {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid #F5F5F5;
      background-color: @light-grey;
      background-image: url('../../../img/icon-pill-white.svg');
      background-repeat: no-repeat;
      background-position: center;
      &.pending {
        background-color: @light-grey;
      }
      &.not-signed {
        background-color: @action-color;
      }
      &.signed {
        background-color: @green;
      }
    }

    .icon {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      line-height: 60px;
      text-align: center;
      position: relative;

      margin-bottom: 15px;
      .icon-badge {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 30px;
        height: 30px;
        background: @blue;
        line-height: 30px;
        text-align: center;
        color: #fff;
        font-weight: 700;
        border-radius: 50%;
      }
    }
    h3 {
      float: left;
      width: 100%;
      .fontSize(14px);
      .lineHeight(18px);
      color: #000;
      font-weight: 700;
      margin-bottom: 0;
    }
    .sub-title {
      float: left;
      width: 100%;
      .fontSize(13px);
      .lineHeight(16px);
      color: @grey;
    }
  }

  span.remove {
    margin: 5px;
    color: @secondary-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.add-contact-person {
  background: none;
  cursor: pointer;
  border: 3px dashed #CACACA;
  border-radius: 5px;
  justify-content: center;
  padding-top: 20px;

  flex-flow: column;

  .add-btn {


    //&:before {
    //  margin-left: -16px;
    //}
  }

  .add-label {
    float: left;
    width: 100%;
    text-align: center;
    color: @grey;
    margin-top: 10px;
  }
}

/* ---------------------------

	Product Detailing Card

--------------------------- */
.product-detailing-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  float: left;
  width: 250px;
  height: auto;
  padding: 15px;


  .content {
    float: left;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
    padding: 20px 20px 40px 20px;
    text-align: center;
    display: flex;
    position: relative;
    flex-flow: column;
    flex-wrap: wrap;

    .icon {
      display: inline-block;
      width: 100%;
      height: 36px;
      background: url('../../../img/icon-pill-grey.svg') no-repeat center;
      background-size: 36px;
      text-align: center;
      position: relative;
      margin-top: 20px;
      margin-bottom: 15px;
    }

    h3 {
      float: left;
      width: 100%;
      .fontSize(14px);
      .lineHeight(18px);
      color: #000;
      font-weight: 700;
      margin-bottom: 0;
    }

    .product-strength {
      float: left;
      width: 100%;
      .fontSize(13px);
      .lineHeight(16px);
      color: @grey;
    }
  }

  span.remove {
    margin: 5px;
    color: @secondary-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.collapse-container{

  .closed{
    margin-top: 15px;
  }


  .re-collapse-item{
    margin-top: 15px;
  }

}
