@import "./PopoverCard/_popoverCard";
@import "./Components/_personDetailCards";
@import "./Components/DownloadBadge/_downloadBadge";
@import "./create/_createPersons";
@import "./_personSiteAffiliations";

.person-view-container {

  .components-dropdown-dropdown-button {
    display: none;
  }

  .apb-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 23.5px;

    .ant-form-item-label {
      text-align: left;
    }

    .ant-input-suffix {
      opacity: 50%;
    }
  }
}


// TODO: This shall be converted to a react component called 'DetailedPage'
.person-detail-view-content-container-test-2 {

  .main-content {
    margin-top: 10px;
  }



  span {
    display: flex;

    .gdpr-ready {
      height: 30px;
      margin-left: 10px;
    }

    h6 {
      margin-top: 8px;
    }
  }

  .gdpr-person-header {
    padding-left: 8px;
    padding-top: 4px;
  }

  .ant-tabs-nav-container {
    margin-left: @padding-left-detail-views;
  }

  .person-title-section {
    float: left;
    width: 75%;
  }

  .person-button-section {
    float: left;
    width: 25%;
  }

  .icon-badge {
    width: 30px;
    height: 30px;
    font-size: 16px;
    margin-left: 10px;
    background: @blue;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    border-radius: 50%;
  }

  .content-top {
    margin-bottom: 0 !important;
  }

  .person-affiliations {
    margin-bottom: 40px;
  }

  .content-section .section-actions {
    margin-top: 0;
  }

  .person-consent-instruction-fields {
    margin-left: 20px;

    h1 {
      margin: 0;
    }

    input {
      margin-left: 5px;
    }
  }

  b.gdpr {
    padding-top: 5px;
    margin-right: 5px;
  }

  p.gdpr, p.filter-code {
    float: left;
    width: 80px;

  }

  .hcp-responsible-area-user {
    padding-top: 8px;
  }

  .person-filter-codes {
    margin-top: 20px;
    width: 50%;

    .button-tab-container {
      float: right;
    }
  }


  .person-tab-section {
    margin-top: 9px;

    .ant-tabs-nav {
      margin-left: @padding-left-detail-views;
    }

  }

  .sort {
    width: 100px;
    float: right;
  }

  .custom-field-container {
    border-bottom: solid 2px #e9e9e9;
    margin-bottom: @large;

    .ant-row {
      margin-top: @x-large;
      margin-bottom: @x-large;
    }
  }

  .consent-person-header {
    h2 {
      float: left;
    }

    .anticon {
      margin-left: 8px;
    }

  }

  .responsible-users-container {
    width: 50%;

    .responsible-users-headers {
      margin-top: 25px;
    }

  }


  .person-detail-tab {
    .content-section-person-container {
      display: none;
    }
  }

  .person-gdpr-field {
    margin-left: 20px;
    margin-top: 20px;

    h1 {
      margin: 0;
    }

    .NOT_ACCEPTED {
      background: url('../../../img/png/gdpr-reject.png') no-repeat center;
      height: 55px;
      width: 119px;
      margin-bottom: 10px;
    }

    .ACCEPTED {
      background: url('../../../img/png/gdpr-accept.png') no-repeat center;
      height: 55px;
      width: 119px;
      margin-bottom: 10px;
    }

    .UNKNOWN {
      background: url('../../../img/png/gdpr-unknown.png') no-repeat center;
      height: 55px;
      width: 119px;
      margin-bottom: 10px;
    }

  }

  .person-detail {
    margin-bottom: 45px;

    .person-dispay-fields {
      float: left;
      width: 200px;
      margin: 50px 50px 0 0;

      .field-label {
        font-weight: bold;
        .fontSize(14px);
        color: black;
      }

    }

  }

  .content-section-person-container {
    border-top: solid 2px @border-color;
    position: relative;

    .content-section-header {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 1px;
      height: 25px;
      border: solid 2px @border-color;
      border-radius: 10px;
      text-align: center;
      position: absolute;
      background-color: @background-color-grey;
      top: -15px;
      left: 0;
      right: 0;
      margin: auto;
      width: 150px;
    }
  }

  .clear {
    clear: both;
  }

  .card-container > .ant-tabs-card > .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
  }

  .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }

  .card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
  }

  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;

  }

  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
  }
}
