
.list-dynamic-rules-search-overview-container {
  background-color: @white;

  .title-and-actions {
    padding: @padding-md @padding-lg;
    border-bottom: 1px solid @border-color-base;
    height: 60px;
    display: flex;
    justify-content: space-between;
    .left {
      color: @heading-color;
      .fontSize(@font-size + 1);
      font-weight: 500;
    }
  }

  .countries {
    padding: @padding-md @padding-lg;
    border-bottom: 1px solid @border-color-base;

    display: flex;
    align-items: center;
  }
}
