
.activity-details-time-span-edit-container {

  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: repeat(2, 34px);

  grid-column-gap: @padding-sm;

  .datetime-input {
    display: flex;
  }

  .actions {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    display: flex;
    align-items: center;

    .icon-container {
      padding: @padding-xss;

      .icon-cancel-outlined {
        svg { color: gray }
      }

      svg {
        font-size: 24px;
      }
    }
  }

}

