@import "types/_criteriaMultiSelect";
@import "types/_criteriaLovModal";
@import "types/_criteriaDateOption";

.criteria-input-container {
  span.meta-label {
    color: white;
    margin-left: 15px;
    position: relative;
    &::before {
      content: "";
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-right: 2px solid white;
      display: block;
      width: calc(100% - 10px);
      height: calc(100% + 5px);
      top: 10px;
      left: -10px;
      position: absolute;

    }
    .meta-label-left, .meta-label-right {
      background: #6b6b6b;
      padding: 0 5px;
      position: relative;
      z-index: 23;
    }
    .meta-label-right {
      margin-left: 10px;
    }
  }
  span.label {
    padding: 0 0 9px 12px;
    font-weight: 400;
    &::after {
      content: ':';
    }
  }

  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > * {
      flex: 1;
      margin: 0;
      &.anticon {
        flex: 0;
        padding: 12px;
        cursor: grab;
      }
    }

    .ant-space-item {
      width: 100%;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
    .disabled {
      pointer-events: none;
    }
  }

  .ant-form-item-explain.ant-form-item-explain {
    display: none;
  }
  .ant-select-selection-item-content {
    max-width: 130px;
  }
}
.criteria-input-container.contrast-label {
  span.label {
    color: @white;
  }
}
