.future-label-container {

  div.futureDateVisible {
    float: right;
    padding: 5px 10px 5px 15px;
    font-size: larger;

    margin-bottom: 50px;

    &.blue {
      border-left: 4px solid @blue;
    }

    &.light-grey {
      border-left: 4px solid @action-color;
    }

    &.green {
      border-left: 4px solid @green;
    }
    &.light-green {
      border-left: 4px solid @light-green;
    }

    &.red {
      border-left: 4px solid @red;
    }

    &.orange {
      border-left: @padding-xss solid @action-color;
    }
  }

  div.futureDateInvisible {
    display: none;
  }

  .futureDate {
    color: @green;
    font-weight: bold;
  }

  td.hasFutureDate {
    border-left: 4px solid @green;
    padding: 16px 16px 16px 14px !important;
  }
}

