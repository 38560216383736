
.entities-search-title-and-countries-container {
  display: flex;
  justify-content: space-between;

  .left {
    padding: @padding-xs 0 20px 0;
    display: flex;
    column-gap: @padding-sm;
    .title {
      .fontSize(@font-size + 1);
      font-weight: 500;
      color: white;
      &.bounding-box { color: @heading-color; }
    }

    .flags {
      display: flex;
      column-gap: @padding-sm;

      .ant-btn-link {
        color: white;
        &.bounding-box { color: @heading-color; }
      }
    }
  }
  .right {
    padding-top: @padding-xs;
  }
}

.entities-search-title-and-countries-modal-container {

  .country-check {
    width: 100%;
    > span:not(.ant-checkbox) {
      width: 100%;
    }
    .country-check-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

}
